/*Import Packages*/
import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import firebase from 'firebase';

/*Import Service*/
import FirebaseConfig from './config/firebaseConfig.js';

/*Import pages*/
import Header from './components/navigations/header.js';
import VendorMain from './components/vendor/main.js';
import Login from './components/login/login.js';


// V2 Changes
import cardList from './container/dashboard/main.js';
import ProductDetail from './container/detail/main.js';
import VendorsMain from './container/vendors/main.js';
import ChatMain from './container/chat/main.js';
import FaqMain from './container/faq/main.js';
import Onboard from './components/login/onboard.js';
import PasswordReset from './components/login/passwordReset.js';
import ForgotPassword from './components/login/forgotPassword.js';
import Resources from './container/resource/main.js';
import ResourceVideoDetails from './container/resource/ResourceVideoDetails';
import BlogDetails from './container/resource/BlogDetail';
import WebinarDetails from './container/resource/WebinarDetails';
// import Dashboard from './container/data/dashboard.js';
import RequestsMain from './container/requests/main.js';
import Subscription from './container/subscription/subscription';

firebase.initializeApp(FirebaseConfig);

class App extends Component {
  componentDidMount() {

  }

  componentDidMount() {
    var tempToken = localStorage.getItem("firebase_token");
    var tempValidate = sessionStorage.getItem("firebaseValidate");

    if(tempToken && !tempValidate){
      this.getFireBaseUID(tempToken);
    }
  }

  getFireBaseUID(tempToken){
    sessionStorage.setItem("firebaseValidate", true);

    firebase.auth().signInWithCustomToken(tempToken).catch(function(error) {
      if(error.code == "auth/invalid-custom-token"){
        this.logout();
      }
      var errorCode = error.code;
      var errorMessage = error.message;
    }.bind(this));
  }

  logout(){
    localStorage.clear();
    window.location.href = '/';
  }

  render() {
    if(!localStorage.getItem("token")){
      return (
        <BrowserRouter>
          <div>
            <Route path="/" exact component={Login}/>
            <Route path="/signup" component={Onboard}/>
            <Route path="/login" exact component={Login}/>
            <Route path="/vendor" exact component={Login}/>
            <Route path="/password-reset" exact component={PasswordReset}/>
            <Route path="/forgot-password" exact component={ForgotPassword}/>

          </div>
        </BrowserRouter>
      );
    }
    else{
      return (
        <BrowserRouter>
          <div>
            <Header/>
            <Route path="/" exact component={cardList}/>
            <Route path="/product-details" exact component={ProductDetail}/>
            <Route path="/support-chats" exact component={ChatMain}/>
            <Route path="/requests" exact component={RequestsMain}/>
            <Route path="/profile" exact component={RequestsMain}/>
            <Route path="/products" exact component={cardList}/>
            <Route path="/resources" exact component={Resources}/>
            <Route path="/resourceVideo" exact component={ResourceVideoDetails}/>
            <Route path="/blogDetails" exact component={BlogDetails}/>
            <Route path="/webinarDetails" exact component={WebinarDetails} />
            <Route path="/subscription" exact component={Subscription}/>
          </div>
        </BrowserRouter>
      );
    }
  }
}

export default App;
