/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class ReviewText extends Component {
  constructor(props){
    super(props);
    this.state = {
      seeMore: false,
      showAll: false
    }
  }

  componentWillMount() {
    if(this.props.review.length > 250){
      this.setState({ seeMore: true });
    }
  }

  seeMoreClick(status){
    this.setState({ showAll: status });
  }

  render() {
    return (
      <div>
        {
          this.state.showAll ?
            <p className="">{this.props.review}</p>
          :
            <p className="e-p-ellipsis e-p-ellipsis-3">{this.props.review}</p>
        }

        
        {
          this.state.seeMore ?
            <span>
              {
                !this.state.showAll ?
                  <span className="e-product-review-less-txt" onClick={this.seeMoreClick.bind(this, true)}>See more</span>
                :
                  <span className="e-product-review-less-txt" onClick={this.seeMoreClick.bind(this, false)}>See less</span>
              }
            </span>
          :
            
            null
        }
      </div>
    );
  }
}

export default ReviewText;
