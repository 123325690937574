/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


// import service
import GetURLVar from '../../components/service/get-url.js'; 

/*Import custom components and assets*/
import AddProduct from '../../components/popups/addProduct';
import ConfirmationModal from '../../components/popups/confirmationModal';
import CommentReplay from '../../components/popups/commentReplay';
import APIPost from '../../components/apiCall/apiPost';
import ReviewText from './reviewText.js';
import devconfig from '../../config/config.js';
import NoDataFound from '../../components/common/noData';
import Links from '../../components/navigations/Links';
import AddFaq from '../../components/popups/addFaq';
import AvgReview from '../../components/startRating/avgRating';
import Toast from '../../components/popups/Toast';
import ApiLoader from '../../components/Loaders/ApiLoader';


import ProductDetails from '../../container/detail//ProductDetails';
import Review from '../../container/detail/Review';
import FaqPage from '../../container/detail/FaqPage';


import fourstars from '../../assets/images/four-stars.svg';
import linkarrow from '../../assets/images/linkarrow.svg';
// import IdeasWithSmallcase from '../../assets/images/ideaswithsmallcase.svg';
import Delete from '../../assets/images/delete-product.svg';
// import optimumgrp from '../../assets//images/marketplaceDetails/optimumgrp.svg';
// import sensigroup from '../../assets//images/marketplaceDetails/sensigrop.svg';
// import alicebluegrp from '../../assets//images/marketplaceDetails/alicebluegrp.svg';
// import SimpleSlider from '../../components/slider/SliderWithNav';



class ProductDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      seeMore: false,
      data: null,
      cardID: null,
      modalFilterTypeOptions: [],
      modalFilterLevelOptions: [],
      commentRemove: 0,
      confirmationModalType: "product",
      commentReplay: 0,
      reviews: [],
      reviewPagenum: 1,
      showLoadMore: false,
      replyModal: true,
      requestNewProd: false,
      requestDeleteProd: false,
      tabIndex: 1,
      activeItem: "",
      modalClass: false
    }
  }

  
 

 componentDidMount(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    GetURLVar().then(function(query){ 
      this.getData(query.id);
      this.setState({id: query.id});
      this.setState({open: query.delete});
      if(this.state.open){
        this.deleModal();
      }
    }.bind(this));
  }
  deleModal(){
   $('#e-confirmModal').modal('toggle');
 }
  getData(tempId){
    this.setState({dataLoading: true});
    var url = devconfig.vendorV3+"/product/detail";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }

   deleteProduct(tempId){
    var url = devconfig.vendorV3+"/product/delete";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.triggerToast(response.message);
         window.location.href = '/products';

      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }

  renderTab(){
    if (this.state.tabIndex == 1){
      return <ProductDetails details={this.state.data} />
    }
    if (this.state.tabIndex == 2){
      return <Review/>
    }
      if (this.state.tabIndex == 3){
      return <FaqPage/>
    }
  }
  openReview = () =>{
    this.setState({ tabIndex: 2 });
  }
  addFaq=()=> {
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('.e-add-faq').addClass('e-login-fly');

}
  triggerToast =(tempMsg) =>{
    this.setState({toast: tempMsg,  toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }


  editProduct(){
    this.setState({modalClass: 'e-login-fly'});
  }

  editModalClose(){
    this.setState({modalClass: false});
    this.getData(this.state.id);
  }

  editSuccess(){
    this.setState({modalClass: false});
    this.getData(this.state.id);
    this.triggerToast("Product successfully updated.")
  }



  render() {


      return (
        <React.Fragment>
        {
          this.state.dataLoading ?
          <ApiLoader/>
          :
          null
        }
      {

        this.state.data?
        <React.Fragment>
          <div className="e-body">
            <div className="container-fluid px-0 ">
              <section className="e-marketDetails-header-sec">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <Links className="e-marketDetails-link"  activeClassName="" name="Products" url="/products"/>
                      <img src={linkarrow} alt="arrow" className="mx-2"/>
                      <Links className="e-marketDetails-link-color"  activeClassName="" name={this.state.data.product_name} url="smallcase"/>
                    </div>
                    <div className="col-lg-4 text-right">
                      <span className="e-edit-details e-p-btn mr-3"  onClick={this.editProduct.bind(this)} >Edit Details</span>
                      <span className="e-dd-faq e-p-btn mr-3"  onClick={this.addFaq.bind(this)}>+ Add FAQ's</span>
                      <span className="e-confirmation"  onClick={this.deleModal}></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <h6 className="e-marketDetails-h5">{this.state.data.product_name} </h6>
                      <h6 className="e-marketDetails-h5 pb-2">{"Product ID: "+this.state.data.product_id} </h6>
                      <h4 className="e-marketDetails-h4 pb-2">{this.state.data.title}</h4>
                      {
                        this.state.data.review_count ?
                          <React.Fragment>
                            <AvgReview rating={this.state.data.rating}/>
                            <span className="e-mrktdtls-review-span ml-3 mr-2" onClick={this.openReview}>{this.state.data.review_count} Reviews</span> 
                            <span className="e-brdr-right-span mr-4">|</span>
                          </React.Fragment>
                        :
                          null
                      }
                      {
                        this.state.data.filters.map((item, index) => {
                          return(
                            <span className="e-box-span mr-2">{item.name}</span>
                            );
                        })
                      }
                      <p className="mt-4 e-marketDetails-p">{this.state.data.description}</p>
                    </div>
                    <div className="col-lg-4 text-right mt-5">
                      <img src={this.state.data.illustration}  alt="bg image" className="mt-4 e-prod-detail-illustration"/>
                    </div>
                  </div>
                </div>
              </section>

          </div>
         <div className="container">
            <section>
              <div className="e-tab">
                <button className={this.state.tabIndex == 1 ?" mr-3 e-tablinks e-tabbtn-active": "mr-3 e-tablinks "} id="pro-tab" onClick={() => {this.setState({tabIndex: 1})}}>Product Details</button>
                <button className={this.state.tabIndex == 2 ?" mr-3 e-tablinks e-tabbtn-active": "mr-3 e-tablinks "} onClick={() => {this.setState({tabIndex: 2})}}>Reviews</button>
                <button className={this.state.tabIndex == 3 ?" mr-3 e-tablinks e-tabbtn-active": "mr-3 e-tablinks "} onClick={() => {this.setState({tabIndex: 3})}}>FAQs & Support</button>
              </div>
                {this.renderTab()}
            </section>
          </div>




        <ConfirmationModal modalTitle="Confirm Delete" modalContent="Are you sure want to delete " confirmSuccess={()=>{this.deleteProduct(this.state.id)}}/>
        <AddFaq confirmSuccess={(tempMsg)=>{this.triggerToast(tempMsg)}}/>
          {
            this.state.toast ?
              <Toast data={this.state.toast} type={this.state.toastType}/>
            :
            null
           }
             {
          this.state.data ?
            <AddProduct data={this.state.data} modalClass={this.state.modalClass} modalClose={this.editModalClose.bind(this)} success={this.editSuccess.bind(this)}/>
          :
            null
        }
        </div>
        </React.Fragment>
        :
        null
        }
      

      </React.Fragment>

      );



  }
}

export default ProductDetail;
  