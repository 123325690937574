/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class Textarea extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: ""
    }
  }

  componentDidMount(){
    
  }

  render() {
    return (
      <dl className="ev-input-content-wrap e-m-bottom-20">
        <dd className="ev-input-contentbox ev-small-input-contentbox">
          {/*<textarea id={this.props.id} value={this.state.value} type={this.props.type} placeholder={this.props.placeholder} onChange={this.valueChange.bind(this)} required></textarea>*/}
          

          <textarea
            value={this.props.value} 
            name={this.props.id}
            type={this.props.type} 
            className={this.props.uppercase ? this.props.className + "text-uppercase" : this.props.className}
            style={this.props.uppercase ? {textTransform: 'uppercase'}: {}}
            required
            // maxLength={this.props.maxLength ? this.props.maxLength : 100}
            {...this.props}
          ></textarea>


        </dd>
      </dl>
    );
  }
}

export default Textarea;




