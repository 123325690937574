/*Package importing*/
import React, { Component } from 'react';
import moment from 'moment';
import firebase from 'firebase';
import database from 'firebase';

/*Assets and Components*/
import SelectBox from '../../components/common/selectBox';
import ChatPlaceholder from '../../components/placeholders/chatPlaceholder';
import Chatbox from './chatbox';
import NoDataFound from '../../components/common/noData';

class ChatMain extends Component {
  constructor(props){
    super(props);
    this.state = {
      typeFilterDefault: { value: "chats", label: 'Open' },
      messages: [],
      loading: null,
      activeMessage: null,
      chatRerender: true,
      activeIndex: 0,
      noChats: false,
      typeFilter: "chats"
    }
  }

  componentDidMount() {
    this.getFirebaseDB(this.state.typeFilter);
  }

  getFirebaseDB(tempTyp){
    this.setState({loading: true, messages: []});
    var tempUID = localStorage.getItem("uid");

    firebase.database().ref(tempTyp).child("admin").orderByChild("vendor_id").equalTo(tempUID).on('value', function(snapshot) {

      if(snapshot.val()){
        var data = [];
        this.setState({messages: []})
        snapshot.forEach(snap => {
          var chat = snap.val();
          chat["convoId"] = snap.key;
          data.push(chat);
        });

        var newData = data.sort(function(a, b){
          var a1= a.reverseTime, b1= b.reverseTime;
          if(a1== b1) return 0;
          return a1 > b1? 1: -1;
        });

        this.setState({loading: false, messages: newData, activeMessage: data[0], noChats: false, activeIndex:0});
      }
      else{
        this.setState({noChats: true});
      }

    }.bind(this));
  }

  selectTypeChange(tempData){
    this.setState({ typeFilterDefault: {value: tempData.value, label: tempData.label}, activeMessage: null});
    this.getFirebaseDB(tempData.value);
  }

  dateToFromNowDaily( myDate ) {
    // get from-now for this date
    var fromNow = moment( myDate ).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay:  '[Yesterday]',
      sameDay:  '[Today]',
      nextDay:  '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  }

  messageItemClick(tempItem, tempIndex){
    this.setState({activeMessage: tempItem, chatRerender: !this.state.chatRerender, activeIndex: tempIndex});
  }

  render() {
    const options = [
      { value: "chats", label: 'Open' },
      { value: "chatsClosed", label: 'Closed' }
    ]

    return (
      <div className="container e-main-content pt-4">
        <div className="row">

          {
            this.state.noChats ?
              <div className="col-lg-4">
                <div className="e-conversation-wrap">
                  <div className="e-conversation-head d-flex justify-content-between">
                    <h6>All Conversations</h6>
                    <span className="e-conversation-select">
                      <SelectBox options={options} defaultValue={this.state.typeFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                    </span>
                  </div>
                  <div>
                    <NoDataFound data="No messages found."/>
                  </div>
                </div>
              </div>
            :
              <div className="col-lg-4">
              {
                this.state.messages.length ?
                  <div className="e-conversation-wrap">
                    <div className="e-conversation-head d-flex justify-content-between">
                      <h6>All Conversations</h6>
                      <span className="e-conversation-select">
                        <SelectBox options={options} defaultValue={this.state.typeFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                      </span>
                    </div>
                    {
                      this.state.messages.map(function(item, index){

                        var nameSplit = item.sender_name.split(" ");
                        var placeholder = "";
                        if(nameSplit.length == 1){
                          placeholder = nameSplit[0].charAt(0)+nameSplit[0].charAt(1);
                        }
                        else{
                          placeholder = nameSplit[0].charAt(0)+nameSplit[1].charAt(0);
                        }

                        var tempClass = "e-conversation-item";
                        if(!item.seen){
                          tempClass = tempClass + " e-unseen"
                        }
                        if(this.state.activeIndex === index){
                          tempClass = tempClass + " e-active"
                        }

                        return(
                          <div className={tempClass} key={index} onClick={this.messageItemClick.bind(this, item, index)}>
                            <div className="media">
                              <span className="e-request-placeholder">{placeholder}</span>
                              <div className="media-body ml-3">
                                <h5 className="mb-2">{item.userName} <span className="e-chat-clientID"></span></h5>
                                <p className="mb-2">{this.dateToFromNowDaily(item.updated) + " " + moment(item.updated).format("HH:mm")}, {item.alice_blue_id ? <span>Client ID:{item.alice_blue_id}</span> : null}</p>
                              </div>
                              <span className="e-unread-dot"></span>
                            </div>
                          </div>
                        )
                      }.bind(this))
                    }
                  </div>
                :
                  <div className="e-conversation-wrap">
                    <div className="e-conversation-head d-flex justify-content-between">
                      <h6>All Conversations</h6>
                    </div>
                    <div className="mt-2">
                      <ChatPlaceholder/>
                    </div>
                      <ChatPlaceholder/>
                    <div>
                      <ChatPlaceholder/>
                    </div>
                  </div>
              }
            </div>
          }

          {
            this.state.activeMessage ?
              <div className="col-lg-8">
                <Chatbox item={this.state.activeMessage}/>
              </div>
            :
              <div className="col-lg-8">
                <div className="e-chat-wrap">
                  <div className="e-chat-head-wrap d-flex justify-content-between">
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ChatMain;
