import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
class Time extends Component {
  
  onChange = time => this.setState({ time })
  render() {
    return (
      <div>
        <TimePicker
        className="e-time-picker"
        showSecond={this.props.showSecond}
        value={this.props.time}
        {...this.props}
      />
      </div>
    );
  }
}
export default Time;