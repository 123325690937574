/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import devconfig from '../../config/config.js';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import noResultImg from '../../assets/images/no-result.png';
import PlaceholderStyleOneLarge from '.././placeholders/placeholderCol1Large.js';

class VendorMain extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      sortType: true,
      file: null,
      fileTwo: null,
      catName: "",
      price: "",
      offer: "",
      desc: "",
      topColor: "",
      bottomColor: "",
      vendorCatList: {},
      dataLoading: true,
      removeCat: null,
      removeFilterType: null,
      editID: null,
      editModal: false,
      editFilterType: null,
      mainCat: [{value: 1, label: "Investor"},{value: 2, label: "Traders"},{value: 3, label: "Strategist"}],
      mainCatOption: {value: 1, label: "Investor"},
      editItem: {},
      link: "",
      removeID: ""
    }
  }

  componentDidMount(){
    this.prodListAPI(1);
  }

  prodListAPI(tempFilterType){
    this.setState({dataLoading: true});
    $.ajax({
      type: "POST",
      url:  devconfig.admin +'/cards/list',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "filter_type" : 0
      })
    }).then(function (response) {
      if(response.status){
        this.setState({ vendorList: response.data, dataLoading: false});
      }
      else{
        this.setState({ vendorList: [], dataLoading: false});
      }
    }.bind(this))
  }

  sortClick(){
    if(this.state.sortType){
      this.prodListAPI(2);
    }
    else{
      this.prodListAPI(1);
    }
    this.setState({sortType: !this.state.sortType});

  }

  uploadBtnClick(e) {
    $('.e-ct-theme-img-upload-btn').click();
  }
  uploadBtnClickTwo(e) {
    $('.e-ct-theme-img-upload-btn-two').click();
  }

  uploadDocument(e) {
    var tempFileName = e.target.files[0].name;
    var fileReader = new FileReader();


    if (e.target.files != null) {
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onloadend = function (event) {
          var file = event.target.result;
          // this.setState({ file: event.target.result})
          $.ajax({
              type: "POST",
              url: devconfig.admin +'/utils/upload-image',
              dataType: 'json',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.state.accessToken
              },
              data: JSON.stringify({
                'file': file,
                'fileName': tempFileName,
                "key": "card"
              })
          }).then(function (response) {
              if (response.status) {
                this.setState({ file: response.cdn_url})
              }
          }.bind(this))
      }.bind(this)
    }
  }

  uploadDocumentTwo(e) {
    var tempFileName = e.target.files[0].name;
    var fileReader = new FileReader();


    if (e.target.files != null) {
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onloadend = function (event) {
          var file = event.target.result;
          // this.setState({ file: event.target.result})
          $.ajax({
              type: "POST",
              url: devconfig.admin +'/utils/upload-image',
              dataType: 'json',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.state.accessToken
              },
              data: JSON.stringify({
                  'file': file,
                  'fileName': tempFileName,
                  "key": "card"
              })
          }).then(function (response) {
              if (response.status) {
                this.setState({ fileTwo: response.cdn_url})
              }
          }.bind(this))
      }.bind(this)
    }
  }

  catNameChange(e){
    this.setState({catName: e.target.value});
  }
  priceChange(e){
    this.setState({price: e.target.value});
  }
  linkChange(e){
    this.setState({link: e.target.value});
  }
  offerChange(e){
    this.setState({offer: e.target.value});
  }
  descChange(e){
    this.setState({desc: e.target.value});

    if(e.target.value){
      $("#p-desc").addClass("active");
    }
    else{
      $("#p-desc").removeClass("active");
    }
  }
  topColorChange(e){
    this.setState({topColor: e.target.value});
  }
  bottomColorChange(e){
    this.setState({bottomColor: e.target.value});
  }

  cancelModal(e){
    this.setState({file: null, catName: ""});
  }
  manageCatClick(){
    this.setState({file: null, catName: "", editModal: false});
  }

  addCatgory(){
    $.ajax({
      type: "POST",
      url: devconfig.admin +'/category/new-couponcategory',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "name" : this.state.catName,
        "image_url": this.state.file
      })
    }).then(function (response) {
      if(response.status){
        this.setState({ file: null, catName: "", sortType: false});
        $('#addcatgory').modal('hide');
        this.prodListAPI(2);
      }
      else{
        this.notify(response.message);
      }
    }.bind(this))
  }

  removeCatgoryAPI(){
    $.ajax({
      type: "POST",
      url: devconfig.admin +'/category/delete-couponcategory',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "category_id" : this.state.removeCat
      })
    }).then(function (response) {
      if(response.status){
        this.setState({ removeCat: null, removeFilterType: null});
        $('#removecatgory').modal('hide');
        if(this.state.removeFilterType == 1){
          this.prodListAPI(1);
        }
        else{
          this.prodListAPI(2);
        }
      }
      else{
        this.notify(response.message);
      }
    }.bind(this))
  }

  removeCatgoryModalClick(catId, filterType){
    this.setState({ removeCat: catId, removeFilterType: filterType });
  }

  editCatgory(item, filterType){
     this.setState({ file: item.image_url, catName: item.category, editID: item.category_id, editModal: true, editFilterType: filterType});
      $('#addcatgory').modal('show');
  }

  editCatgoryAPI(){
    $.ajax({
      type: "POST",
      url: devconfig.admin +'/category/update-couponcategory',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "category_id" : this.state.editID,
        "name" : this.state.catName,
        "image_url": this.state.file
      })
    }).then(function (response) {
      if(response.status){
        this.setState({ file: null, catName: "", editID: null, editModal: false});
        $('#addcatgory').modal('hide');
        if(this.state.editFilterType == 1){
            this.prodListAPI(1);
        }
        else{
          this.prodListAPI(2);
        }
      }
      else{
        this.notify(response.message);
      }
    }.bind(this))
  }

  mainCatChange = (mainCatOption) => {
    this.setState({ mainCatOption });
  }
  descChangeEdit(e){
    this.setState({desc: e.target.value});

    if(e.target.value){
      $("#p-desc-edit").addClass("active");
    }
    else{
      $("#p-desc-edit").removeClass("active");
    }
  }

  uploadBtnClickEdit(e) {
    $('.e-ct-theme-img-upload-btn-edit').click();
  }
  uploadBtnClickTwoEdit(e) {
    $('.e-ct-theme-img-upload-btn-two-edit').click();
  }

  editModalClick(cardValue){
    this.setState({ editItem: {}});

    var filteredValue = Object.keys(this.state.mainCat).filter(function (item) {
      return this.state.mainCat[item].value == this.state.vendorList[cardValue].filter_id;
    }.bind(this))

    this.setState({
      editItem: this.state.vendorList[cardValue],
      file: this.state.vendorList[cardValue].logo,
      fileTwo: this.state.vendorList[cardValue].illustration,
      catName: this.state.vendorList[cardValue].title,
      price: this.state.vendorList[cardValue].price,
      offer: this.state.vendorList[cardValue].offer,
      desc: this.state.vendorList[cardValue].body,
      link: this.state.vendorList[cardValue].link,
      topColor: this.state.vendorList[cardValue].gradient1,
      bottomColor: this.state.vendorList[cardValue].gradient2,
      mainCatOption: { value: this.state.mainCat[filteredValue[0]].value, label: this.state.mainCat[filteredValue[0]].label },
      cardID : this.state.vendorList[cardValue].id
    });
    $("#p-desc-edit").addClass("active");
  }

  editCardAPI(){
    var tempTypeArray = [];
    Object.keys(this.state.mainCatOption).map(function(key, index){
    tempTypeArray.push(this.state.mainCatOption[key].value)
    }.bind(this))

    $.ajax({
      type: "POST",
      url: devconfig.admin +'/cards/update',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "filter_ids": tempTypeArray,
        "card_id": this.state.cardID,
        "gradient1": this.state.topColor,
        "gradient2": this.state.bottomColor,
        "logo": this.state.file,
        "illustration": this.state.fileTwo,
        "title": this.state.catName,
        "description": this.state.desc,
        "price": this.state.price,
        "offer": this.state.offer,
        "link": this.state.link
      })
    }).then(function (response) {
      if(response.status){
        // this.setState({ file: null, catName: "", editID: null, editModal: false});
        $('#editcatgory').modal('hide');
        this.prodListAPI(0);
      }
      else{
        this.notify(response.message);
      }
    }.bind(this))
  }

  createModalClick(){
    this.setState({
      editItem: {},
      file: null,
      fileTwo: null,
      catName: "",
      price: "",
      offer: "",
      desc: "",
      link: "",
      topColor: "",
      bottomColor: "",
      mainCatOption: {value: 1, label: "Investor"},
      cardID : null
    });
  }

  addCardAPI(){


    var tempTypeArray = [];
    Object.keys(this.state.mainCatOption).map(function(key, index){
      tempTypeArray.push(this.state.mainCatOption[key].value)
    }.bind(this))
    // Object.keys(this.state.mainCatOption).length


    $.ajax({
      type: "POST",
      url: devconfig.admin +'/cards/create',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "filter_ids": tempTypeArray,
        "gradient1": this.state.topColor,
        "gradient2": this.state.bottomColor,
        "logo": this.state.file,
        "illustration": this.state.fileTwo,
        "title": this.state.catName,
        "description": this.state.desc,
        "price": this.state.price,
        "offer": this.state.offer,
        "link": this.state.link
      })
    }).then(function (response) {
      if(response.status){
        $('#addcatgory').modal('hide');
        this.prodListAPI(0);
      }
      else{
        this.notify(response.message);
      }
    }.bind(this))
  }

  removeModalClick(cardID){
    this.setState({removeID: cardID})
  }

  removeAPI(){
    $.ajax({
      type: "POST",
      url: devconfig.admin +'/cards/delete',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token")
      },
      data: JSON.stringify({
        "card_id": this.state.removeID
      })
    }).then(function (response) {
      if(response.status){
        $('#removecatgory').modal('hide');
        this.prodListAPI(0);
      }
      else{
        this.notify(response.message);
      }
    }.bind(this))
  }

  render() {
    const { mainCatOption } = this.state;
    return (
      <div className="container">
        <div className="row">
          {/*<SideBar item={"cat-vendor"}/>*/}
          <div className="col-md-12 e-main-content">
            <div className="row e-p-bottom-20">
              <div className="col-md-12 e-align-right">
                <span className="e-p-btn" data-toggle="modal" data-target="#addcatgory" onClick={this.createModalClick.bind(this)}>Add New Card</span>
              </div>
            </div>

            {
              this.state.dataLoading ?
                <div className="row">
                  <div className="col-md-12 e-align-center">
                    <PlaceholderStyleOneLarge/>
                  </div>
                </div>
              :
                <span>
                  {
                    Object.keys(this.state.vendorList).length ?
                      <div className="row e-p-top-20">
                        {
                          Object.keys(this.state.vendorList).map(function(value, index){
                            var tempBgColor = "linear-gradient( to top right, "+this.state.vendorList[value].gradient1+" , "+this.state.vendorList[value].gradient2+")"
                            return(
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12 e-m-bottom-30 e-card-outer-wrap" key={index}>
                                <div className="e-hm-card-wrap e-p-equal-30 " style={{backgroundImage: tempBgColor}}>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 e-p-bottom-50">
                                      <img className="e-hm-prod-logo" src={this.state.vendorList[value].logo} alt="Mutual Fund" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 e-align-right e-p-bottom-50">
                                      <img className="e-hm-prod-icon" src={this.state.vendorList[value].illustration} alt="Mutual Fund" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                  </div>
                                  <h4 className="e-c-brown e-p-bottom-10">{this.state.vendorList[value].title}</h4>
                                  <p className="e-p-bottom-10">{this.state.vendorList[value].body}</p>
                                  <p className="e-font-13">Price : <b className="e-f-weight-600">{this.state.vendorList[value].price}</b></p>
                                  <div className="e-m-bottom-20 col-lg-12 e-p-0">
                                    <span className="e-hm-offer-tag">Offer: <b>{this.state.vendorList[value].offer}</b></span>
                                  </div>
                                    <div className="col-lg-12 e-align-right e-p-0">
                                      <a className="e-p-btn e-m-right-20 e-p-btn-up-effect white e-hm-card-btn e-admin-card-edit" data-toggle="modal" data-target="#editcatgory" onClick={this.editModalClick.bind(this, value)}>Edit</a>
                                      <a className="e-p-btn e-m-right-20 e-p-btn-up-effect white e-hm-card-btn e-admin-card-remove" data-toggle="modal" data-target="#removecatgory" onClick={this.removeModalClick.bind(this, this.state.vendorList[value].id)}>Remove</a>
                                    </div>

                                </div>
                              </div>
                            )
                          }.bind(this))
                        }
                      </div>
                    :
                      null
                }
                </span>
            }
          </div>
        </div>


        {/*Add Card Modal*/}
        <div className="modal fade" id="addcatgory" tabIndex="-1" role="dialog" aria-labelledby="addcatgoryLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Add<br/><span>New Product</span></h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <span className="e-close-icon"></span>
                  </span>
                </button>
              </div>
              <div className="modal-body e-m-bottom-10">
                <div className="row">
                  <div className="col-md-3 e-m-bottom-20">
                    {
                      this.state.file ?
                        <span className="e-modal-img-uploaded-wrap e-inline-block">
                          <img className="mr-3 e-modal-img-uploaded" src={this.state.file}/>
                          <span className="e-modal-img-uploaded-overlay" onClick={this.uploadBtnClick.bind(this)}>Change</span>
                        </span>
                      :
                        <span className="e-modal-img-upload" onClick={this.uploadBtnClick.bind(this)}>
                          Upload product logo
                        </span>
                    }
                    <input className="e-ct-theme-img-upload-btn" type="file" accept=".jpg,.png,.gif" onChange={this.uploadDocument.bind(this)}/>
                  </div>
                  <div className="col-md-3 e-m-bottom-20">
                    {
                      this.state.fileTwo ?
                        <span className="e-modal-img-uploaded-wrap e-inline-block">
                          <img className="mr-3 e-modal-img-uploaded" src={this.state.fileTwo}/>
                          <span className="e-modal-img-uploaded-overlay" onClick={this.uploadBtnClickTwo.bind(this)}>Change</span>
                        </span>
                      :
                        <span className="e-modal-img-upload" onClick={this.uploadBtnClickTwo.bind(this)}>
                          Upload product illustration
                        </span>
                    }
                    <input className="e-ct-theme-img-upload-btn-two" type="file" accept=".jpg,.png,.gif" onChange={this.uploadDocumentTwo.bind(this)}/>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap e-m-bottom-20 ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox ">
                        <input onChange={this.catNameChange.bind(this)} value={this.state.catName} maxLength="100" id="cat-name" className="cat-name" type="text" required/>
                        <label htmlFor="cat-name" className="label cat-name">Title</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" onChange={this.priceChange.bind(this)} value={this.state.price} id="e-price" className="e-price" type="text" required/>
                        <label htmlFor="e-price" className="label e-price">Price</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox" onChange={this.offerChange.bind(this)} value={this.state.offer}>
                        <input rows="5" id="e-offer" className="e-offer" type="text" required/>
                        <label htmlFor="e-offer" className="label e-offer">Offer</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap">
                      <dd className="ev-input-contentbox">
                        <textarea id="p-desc" maxLength="200" type="text" rows="3" onChange={this.descChange.bind(this)} value={this.state.desc} required></textarea>
                        <label htmlFor="p-desc">Description</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" onChange={this.linkChange.bind(this)} value={this.state.link} id="e-link" className="e-link" type="text" required/>
                        <label htmlFor="e-link" className="label e-price">Page Link</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-6">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" id="e-primary" className="e-primary" type="text" onChange={this.topColorChange.bind(this)} value={this.state.topColor} required/>
                        <label htmlFor="e-primary" className="label e-primary">Bottom Left Color</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-6">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" id="e-secondary" className="e-secondary" type="text" onChange={this.bottomColorChange.bind(this)} value={this.state.bottomColor} required/>
                        <label htmlFor="e-secondary" className="label e-secondary">Top Right Color</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-lg-12 e-font-12 e-p-bottom-10">
                    Colors should be in hexadecimal code eg: #66ffcc. Link for <a className="e-color-picker-link" href="https://www.w3schools.com/colors/colors_picker.asp" target="_blank">Color Picker</a>
                  </div>
                  <div className="col-lg-12">
                    <Select className="e-ct-table-SW e-modal-select e-m-bottom-15" isMulti value={mainCatOption} onChange={this.mainCatChange} options={this.state.mainCat} />
                  </div>


                </div>
              </div>
              {
                this.state.editModal ?
                  <div className="modal-footer">
                    <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                    {
                      this.state.file && this.state.catName ?
                        <span className="e-p-btn e-white" onClick={this.editCatgoryAPI.bind(this)}>Update</span>
                      :
                        <span className="e-p-btn e-white e-in-active">Update</span>
                    }
                  </div>
                :
                  <div className="modal-footer">
                    <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                    {
                      this.state.file && this.state.fileTwo && this.state.catName && this.state.price && this.state.offer && this.state.desc && this.state.topColor && this.state.bottomColor && this.state.link && this.state.mainCatOption ?
                        <span className="e-p-btn e-white" onClick={this.addCardAPI.bind(this)}>Create</span>
                      :
                        <span className="e-p-btn e-white e-in-active">Create</span>
                    }
                  </div>
              }

            </div>
          </div>
        </div>

        {/*Edit Card Modal*/}
        <div className="modal fade" id="editcatgory" tabIndex="-1" role="dialog" aria-labelledby="editcatgoryLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Add<br/><span>New Product</span></h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <span className="e-close-icon"></span>
                  </span>
                </button>
              </div>
              <div className="modal-body e-m-bottom-10">
                <div className="row">
                  <div className="col-md-3 e-m-bottom-20">
                    {
                      this.state.file ?
                        <span className="e-modal-img-uploaded-wrap e-inline-block">
                          <img className="mr-3 e-modal-img-uploaded" src={this.state.file}/>
                          <span className="e-modal-img-uploaded-overlay" onClick={this.uploadBtnClickEdit.bind(this)}>Change</span>
                        </span>
                      :
                        <span className="e-modal-img-upload" onClick={this.uploadBtnClickEdit.bind(this)}>
                          Upload product logo
                        </span>
                    }
                    <input className="e-ct-theme-img-upload-btn-edit" type="file" accept=".jpg,.png,.gif" onChange={this.uploadDocument.bind(this)}/>
                  </div>
                  <div className="col-md-3 e-m-bottom-20">
                    {
                      this.state.fileTwo ?
                        <span className="e-modal-img-uploaded-wrap e-inline-block">
                          <img className="mr-3 e-modal-img-uploaded" src={this.state.fileTwo}/>
                          <span className="e-modal-img-uploaded-overlay" onClick={this.uploadBtnClickTwoEdit.bind(this)}>Change</span>
                        </span>
                      :
                        <span className="e-modal-img-upload" onClick={this.uploadBtnClickTwoEdit.bind(this)}>
                          Upload product illustration
                        </span>
                    }
                    <input className="e-ct-theme-img-upload-btn-two-edit" type="file" accept=".jpg,.png,.gif" onChange={this.uploadDocumentTwo.bind(this)}/>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap e-m-bottom-20 ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox ">
                        <input onChange={this.catNameChange.bind(this)} value={this.state.catName} maxLength="100" id="cat-name-edit" className="cat-name" type="text" required/>
                        <label htmlFor="cat-name-edit" className="label cat-name-edit">Title</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" onChange={this.priceChange.bind(this)} value={this.state.price} id="e-price-edit" className="e-price-edit" type="text" required/>
                        <label htmlFor="e-price-edit" className="label e-price-edit">Price</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" id="e-offer-edit" className="e-offer-edit" type="text" onChange={this.offerChange.bind(this)} value={this.state.offer} required/>
                        <label htmlFor="e-offer-edit" className="label e-offer">Offer</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap">
                      <dd className="ev-input-contentbox">
                        <textarea id="p-desc-edit" maxLength="200" type="text" rows="3" onChange={this.descChangeEdit.bind(this)} value={this.state.desc} required></textarea>
                        <label htmlFor="p-desc-edit">Description</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-12">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" onChange={this.linkChange.bind(this)} value={this.state.link} id="e-price-edit" className="e-price-edit" type="text" required/>
                        <label htmlFor="e-price-edit" className="label e-price">Page Link</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-6">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" id="e-primary-edit" className="e-primary-edit" type="text" onChange={this.topColorChange.bind(this)} value={this.state.topColor} required/>
                        <label htmlFor="e-primary-edit" className="label e-primary">Bottom Left Color</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-6">
                    <dl className="ev-input-content-wrap ">
                      <dd className="ev-input-contentbox ev-small-input-contentbox">
                        <input rows="5" id="e-secondary-edit" className="e-secondary-edit" type="text" onChange={this.bottomColorChange.bind(this)} value={this.state.bottomColor} required/>
                        <label htmlFor="e-secondary-edit" className="label e-secondary-edit">Top Right Color</label>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-lg-12 e-font-12 e-p-bottom-10">
                    Colors should be in hexadecimal code eg: #66ffcc. Link for <a className="e-color-picker-link" href="https://www.w3schools.com/colors/colors_picker.asp" target="_blank">Color Picker</a>
                  </div>
                  <div className="col-lg-12">
                    <Select className="e-ct-table-SW e-modal-select e-m-bottom-15" isMulti value={mainCatOption} onChange={this.mainCatChange} options={this.state.mainCat} />
                  </div>


                </div>
              </div>
              {
                this.state.editModal ?
                  <div className="modal-footer">
                    <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                    {
                      this.state.file && this.state.catName ?
                        <span className="e-p-btn e-white" onClick={this.editCatgoryAPI.bind(this)}>Update</span>
                      :
                        <span className="e-p-btn e-white e-in-active">Update</span>
                    }
                  </div>
                :
                  <div className="modal-footer">
                    <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                    {
                      this.state.file && this.state.fileTwo && this.state.catName && this.state.price && this.state.offer && this.state.desc && this.state.topColor && this.state.bottomColor && this.state.link && this.state.mainCatOption ?
                        <span className="e-p-btn e-white" onClick={this.editCardAPI.bind(this)}>Update</span>
                      :
                        <span className="e-p-btn e-white e-in-active">Update</span>
                    }
                  </div>
              }

            </div>
          </div>
        </div>

        {/*Remove confirmation modal*/}
        <div className="modal fade" id="removecatgory" tabIndex="-1" role="dialog" aria-labelledby="removecatgoryLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Remove<br/><span>Products</span></h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <span className="e-close-icon"></span>
                  </span>
                </button>
              </div>
              <div className="modal-body e-m-bottom-10">
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you deleting the Products? Deleting is a permanent action, you can't undo it. Are you sure you want to proceed?</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#removecatgory">Cancel</span>
                <span className="e-p-btn e-white" onClick={this.removeAPI.bind(this)}>Confirm</span>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-left" autoClose={3000} hideProgressBar newestOnTop closeOnClick={false} rtl={false} pauseOnVisibilityChange={false} draggable={false} pauseOnHover/>
      </div>
    );
  }
}

export default VendorMain;
