/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
class FaqItem extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  editClick(){
    this.props.editClick(this.props.data)
  }
  
  render() {

    return (
      <div className="e-faq-item">
        <h6>{this.props.index+1}. {this.props.data.question}</h6>
        <p>{this.props.data.answer}</p>
        <div className="e-faq-brn-wrap">
          <span className="e-p-btn mr-3" onClick={this.editClick.bind(this)} data-toggle="modal" data-target="#e-faqEdit">Edit</span>
          <span className="e-s-btn ">Remove</span>
        </div>
      </div>
    );
  }
}

export default FaqItem;
