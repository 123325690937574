/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import GetURLVar from '../../components/service/get-url.js'; 
// services

/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';
import AddIllustration from '../../assets/images/add-illustartion.svg';
import AddVideo from '../../assets/images/video.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';



class EditVideos extends Component {
  constructor(props){
    super(props);
    this.state = {
      products: null,
      // default: [],
      selectedProduct: [],
      title: this.props.toBeEdit.title,
      url: this.props.toBeEdit.youtube_url,
      videoId: null,
    }
  }

  componentDidMount(){
   this.getProduct();
   GetURLVar().then(function(query){ 
      this.setState({videoId: query.id});
    }.bind(this));
  }

  editVideo=()=>{
    
    var url = devconfig.vendorV3+"/resources/edit-video";
    var data = JSON.stringify({
      "video_id" : parseInt(this.state.videoId),
      "product_ids": this.state.selectedProduct,
      "title": this.state.title,
      "youtube_url" : this.state.url,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         // this.setState({products: response.data});
         this.props.reloadedit();
         this.hideLogin();
      }
      else{
        this.setState({error: response.message, apiLoading: false}) 
      }
    }.bind(this))
  }

  getProduct=()=>{
    var url = devconfig.vendorV3+"/product/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
      }
    }.bind(this))
  }

  handleSelect(tempVal){
    this.setState({ selectedProduct: Array.isArray(tempVal)  ? tempVal.map(x => x.value) : []});
  }


  hideLogin=()=> {
  $('.e-edit-video').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }


  render() {
     const options = this.state.products ?
    
        this.state.products.map((item, index) => {
        return(
         { value: item.id, label: item.name }
        );
      })
    :
      null
    const defaultSelect = this.props.toBeEdit.products? 
    this.props.toBeEdit.products.map((item, index) =>{
      return({ value: item.id, label: item.name });
    })
    :
    null
    return(

      <div className="container text-left  e-edit-video e-add-product-modal">
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="">Edit Tutorial Videos</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
               <span className="e-save e-p-btn float-right" onClick={this.editVideo} data-toggle="modal" data-target="#e-addProduct">Save & Add Tutorials</span>
              </div>
            </div>
            {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6"> 
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }

            <div className="row pt-3">
              <div className="col-lg-6 e-select-product">
                <label>Product</label>
                <SelectBox placeholder="Select the product" options={options} selectType={true} defaultValue={defaultSelect} selectchange={(selectedValue) => this.handleSelect(selectedValue)} />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>Video Link</label>
              </div>
            
                        <div className="col-lg-6">

                         <InputBox placeholder={"Video Title"}
                         value={this.state.title}
                          onChange={(e) => {
                          this.setState({title: e.target.value}) 
                        }} />
                    </div>
                    <div className="col-lg-6 ">

                      <InputBox placeholder="Youtube URL"
                      value={this.state.url}
                      onChange={(e) => {
                          this.setState({url: e.target.value}) 
                        }} />
                    </div>
                </div>
              </div>
             </div>
            </div>

    )
  }
}

export default EditVideos;
