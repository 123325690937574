/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

import devconfig from '../../config/config.js';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import InputBox from '../../components/common/inputBox';
import Checkbox from '../../components/common/checkbox';
/*Assets and Components*/
import logo from '../../assets/images/logo-orange.png';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      successMessage: "",
      check: false,
      checkAgreement: false
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();
    if (query.reset) {
      this.setState({ successMessage: "Password submission success, please login!" });
    }
  }

  loginClick(e) {
    var tempEncodeData = this.state.username.concat(":").concat(this.state.password);
    var encodedData = btoa(tempEncodeData);
    $.ajax({
      type: "GET",
      url: devconfig.vendorV2 + '/auth/login',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Basic " + encodedData,
      }
    }).then(function (response) {
      console.log(response)
      if (response.status_code == 200) {
        localStorage.setItem("access_token", response.access_token);
        localStorage.setItem("firebase_token", response.firebase_token);
        localStorage.setItem("profileData", JSON.stringify(response.data));
        localStorage.setItem("uid", response.data.firebase_uid);
        localStorage.setItem("token", true);
        window.location.href = '/';
      }
      else {
        this.setState({ errorMessage: response.message });
      }
    }.bind(this))
      .catch(function (response) {
        this.setState({ errorMessage: response.responseJSON.message });
      }.bind(this))
  }


  getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  // userChange(e) {
  //   this.setState({username: e.target.value, errorMessage: ""});
  // }
  // passChange(e) {
  //   this.setState({password: e.target.value, errorMessage: ""});
  // }

  forgotPasswordClick() {

    this.setState({ btnClicked: true });
    var url = devconfig.adminV2 + "/faq/delete";
    var data = JSON.stringify({
      "id": this.state.removeId
    })

    APIPost(url, data).then(function (response) {
      if (response.status) {

        this.setState({ successMessage: "Password reset link has been sent to your email!", btnClicked: false });
        setTimeout(
          function () {
            this.setState({ successMessage: "" });
          }.bind(this), 3000
        );
      }
      else {
        this.setState({ errorMessage: response.message, btnClicked: false });
      }
    }.bind(this))
  }

  render() {
    return (
      <div>
        <div className="container-fluid e-login-outer-wrap">
          <div className="row">
            <div className="offset-md-1 col-md-4 e-bg-white e-login-panel e-p-equal-30 e-border-5 e-m-top-100">
              <div>
                <img className="e-logo e-full-width e-m-right-10 e-login-logo" src={logo} />
              </div>
              <div className="e-p-top-20">
                <h2 className="e-card-p-txt">Login <br /><span>to Trade Store Vendor</span></h2>
              </div>
              <div className="e-p-top-50">
                <dl className="ev-input-content-wrap e-m-bottom-20">
                  <dd className="ev-input-contentbox ev-small-input-contentbox">
                    <InputBox placeholder="Email Address"
                      value={this.state.username}
                      onChange={(e) => { this.setState({ username: e.target.value }) }} />

                  </dd>
                </dl>
                <dl className="ev-input-content-wrap">
                  <dd className="ev-input-contentbox ev-small-input-contentbox">
                    <InputBox placeholder="Password"
                      value={this.state.password}
                      onChange={(e) => { this.setState({ password: e.target.value }) }} type="password" />

                  </dd>
                </dl>
              </div>
              <div>
                <label className="e-checkbox-wrap mb-0">
                  <p className="e-normal-p">I've read the <a href="https://docs.google.com/document/d/1jDwA4fth1ly-SGPJYq4AiJSCztu-S3VgYlZG5DVbKlo/edit" className='e-login-link' target='_blank'>Tradestore Agreement</a> and I agree with them</p>
                  <input type="checkbox" checked={this.state.checkAgreement} 
                  onChange={(e) => {this.setState({
                    checkAgreement:e.target.checked
                  })}} 
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label className="e-checkbox-wrap ">
                  <p className="e-normal-p">I've read the <a href="https://docs.google.com/document/d/1oDbtTdQbQ3pWZG7aZjwh0D_69bBHH8xDZYbKPI0tfjA/edit" className='e-login-link' target='_blank'>Terms & Conditions</a> and I agree with them</p>
                  <input type="checkbox" checked={this.state.check} 
                  onChange={(e) => {this.setState({
                    check:e.target.checked
                  })}} 
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="e-forgot-password-wrap">
                <a href="/forgot-password">Forgot Password</a>
              </div>
              <div className="e-p-top-30">
                {
                  this.state.password != "" && this.state.username != "" && this.state.check && this.state.checkAgreement?
                    <span className="e-p-btn e-login-btn" onClick={this.loginClick.bind(this)}>Login</span>
                    :
                    <span className="e-p-btn e-login-btn e-btn-inactive">Login</span>
                }

              </div>
              {/*<div className="e-p-top-30">
                <a className="e-font-12 e-f-weight-500 e-underline" href="/">Forgot Password</a>
              </div>*/}
              {
                this.state.successMessage.length ?
                  <div className="col-lg-12 e-p-top-10 e-p-left-0">
                    <p className="e-f-weight-500 e-c-green pt-3">{this.state.successMessage}</p>
                  </div>
                  :
                  null
              }

              {
                this.state.errorMessage ?
                  <div className="col-lg-12 e-p-top-10 e-p-left-0">
                    <p className="e-f-weight-500 e-c-red pt-3">{this.state.errorMessage}</p>
                  </div>
                  :
                  null
              }

            </div>

          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="e-poweredby-wrap">© 2020 Aliceblue, all rights reserved. Powered by <a href="https://evoqueinnovativelab.com" target="_blank">Evoque Innovative lab</a></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
