import $ from 'jquery';

export default async function APIPost(url, data) {
  return await $.ajax({
    type: "POST",
    url: url,
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': localStorage.getItem("access_token")
    },
    data: data
  })
  .then(function(response){ return response }.bind(this))
  .catch(function(response){ 
    console.log(response.responseJSON);
    if(response.responseJSON.status_code === 401){
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/?session=true";
    }
    else{
      return response.responseJSON 
    }
    
  }.bind(this))
}