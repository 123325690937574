/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class Checkbox extends Component {
  constructor(props){
    super(props);
    this.state = {
    	value: false
    }
  }

  // componentDidMount(){
  //   if(this.props.defaultValue){
  //     this.setState({value: this.props.defaultValue});
  //   }
  // }

  checkClick(e) {
    this.setState({value: e.target.value});
    this.props.valuechange(e.target.value);
  }

  render() {
    return (
      <label className="e-checkbox-wrap ">
        <p className="e-normal-p">{this.props.label}</p>
        <input type="checkbox" checked={this.props.value} {...this.props}/>
        <span className="checkmark"></span>
      </label>
    );
  }
}

export default Checkbox;
