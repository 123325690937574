/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';

import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';

class FaqEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorMessage: "",
      fTitle: "",
      fBody: "",
      data: [],
      btnClicked: false
    }
  }

  componentWillMount() {
    var data = this.props.data;
    if(data){
      this.setState({
        fTitle: data.question,
        fBody: data.answer
      });
    }
  }

  clearStates(){
    this.setState({
      errorMessage: "",
      fTitle: "",
      fBody: "",
      data: [],
      btnClicked: false
    });
  }

  async AddClick(){
    this.setState({btnClicked: true});
    var url = devconfig.adminV2+"/faq/edit";
    var data = JSON.stringify({
      "id" : this.props.data.id,
      "question" : this.state.fTitle,
      "answer" : this.state.fBody
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        var tempId = "#"+this.props.id;
        $(tempId).modal('toggle');
        this.clearStates();
        this.props.faqSuccess();
      }
      else{
        this.setState({errorMessage: response.message, btnClicked: false});
      }
    }.bind(this))
  }

  render() {
    return(
      <div className="modal fade e-modal-wrap" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="e-faqAddLabel" aria-hidden="true">
        <div className="modal-dialog e-modal-editVendor" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">Update Faq</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <InputBox label="Question" id="f-title" type="text" defaultValue={this.state.fTitle} valuechange={(tempValue) => this.setState({fTitle: tempValue, errorMessage: ""})}/> 
                  <Textarea label="Answer" id="f-body" type="text" defaultValue={this.state.fBody} valuechange={(tempValue) => this.setState({fBody: tempValue, errorMessage: ""})}/>
                </div>
                <div className="col-lg-4 mt-3">
                  {
                    this.state.fTitle.length && this.state.fBody.length && !this.state.btnClicked ?
                      <span className="e-p-btn" onClick={this.AddClick.bind(this)}>Update</span>
                    :
                      <span className="e-p-btn e-inActive">Update</span>
                  }
                </div>
                {
                  this.state.errorMessage.length ?
                    <div className="col-lg-12">
                      <div className="mt-4 text-danger">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FaqEdit;
