/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';
import VendorCard from '../../components/card/vendorCard';

class PartneredVendors extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: []
    }
  }

  componentWillMount() {
    this.listData(1);
  }

  listData(tempPageNum){
    var url = devconfig.adminV2+"/vendor/list";
    var data = JSON.stringify({
      "page_num" : tempPageNum
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({data: response.data});
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  cardClick(tempID){
    this.props.detailClick(tempID);
  }

  render() {
    return (
      <div className="col-lg-9">
        <div className="e-partnered-wrap mb-5">
          <h2 className="mb-4">All Vendors</h2>
            {
              this.state.data.length ?
                <div className="row">
                  {
                    this.state.data.map(function(item, index){
                      return(
                        <VendorCard data={item} key={index} cardClickSuccess={this.cardClick.bind(this)}/>
                      )
                    }.bind(this))
                  }
                </div>
              :
                null
            }
        </div>
      </div>
    );
  }
}

export default PartneredVendors;
