
var FirebaseConfig = {
	apiKey: "AIzaSyDoHPip3tDDmdPyltppPblhp5fLTcQefJA",
  authDomain: "tradestore-e6063.firebaseapp.com",
  databaseURL: "https://tradestore-e6063.firebaseio.com",
  projectId: "tradestore-e6063",
  storageBucket: "tradestore-e6063.appspot.com",
  messagingSenderId: "650602951430",
  appId: "1:650602951430:web:a314a6ac0e460b1770a487",
  measurementId: "G-FLPQRN03HD"
}

module.exports = FirebaseConfig;