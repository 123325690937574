/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import devconfig from '../../config/config.js';
import APIPost from '../apiCall/apiPost';
import PasswordValidation from '../validation/password';
import logo from '../../assets/images/logo-orange.png';
import InputBox from '../../components/common/inputBox';

class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorMessage: "",
      email: "",
      btnClicked: false,
      successMessage: ""
    }
  }

  componentWillMount() {
    
  }


  sendClick(e){
    this.setState({btnClicked: true});
    var url = devconfig.vendorV2+"/auth/forgot-password";
    var data = JSON.stringify({
      "email" : this.state.email
    })

    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        this.setState({successMessage: "Password reset link has been sent to your email!", btnClicked: false, email: ""});
        setTimeout(
          function() {
            this.setState({successMessage: ""});
            window.location.href = '/';
          }.bind(this), 3000
        );
      }
      else{
        this.setState({errorMessage: response.message, btnClicked: false});
      }
    }.bind(this))
  }

  emailChange(e) {
    this.setState({email: e.target.value, errorMessage: ""});
  }
  confirmPassChange(e) {
    this.setState({confirmPassword: e.target.value, errorMessage: ""});
  }

  render() {
    return (
      <div className="container-fluid e-login-outer-wrap">
        <div className="row">
          <div className="offset-md-1 col-md-4 e-bg-white e-login-panel e-p-equal-30 e-border-5 e-m-top-100">
            <div>
              <img className="e-logo e-full-width e-m-right-10 e-login-logo" src={logo}/>
            </div>
            <div className="e-p-top-20">
              <h2 className="e-card-p-txt">Forgot <br/><span>Password</span></h2>
            </div>
            <div className="e-p-top-50">
              <dl className="ev-input-content-wrap e-m-bottom-20">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <InputBox id="u-email" className="u-email" placeholder="Registered Email"
                    value={this.state.email}
                    onChange={(e)=>{this.setState({email: e.target.value})}} type="text" />
                </dd>
              </dl>
            </div>
            <div className="e-p-top-30"> 
              {
                this.state.email != "" && !this.state.btnClicked ?
                  <span className="e-p-btn e-login-btn" onClick={this.sendClick.bind(this)}>Send Reset Link</span>
                :
                  <span className="e-p-btn e-login-btn e-btn-inactive">Send Reset Link</span>
              }

            </div>
            {/*<div className="e-p-top-30">
              <a className="e-font-12 e-f-weight-500 e-underline" href="/">Forgot Password</a>
            </div>*/}
            {
              this.state.successMessage.length ?
                <div className="col-lg-12 e-p-top-10 e-p-left-0">
                  <p className="e-f-weight-500 e-c-green pt-3">{this.state.successMessage}</p>
                </div>
              :
                null
            }
            <div className="col-lg-12 e-p-top-10 e-p-left-0">
              <p className="e-f-weight-500 e-c-red pt-3">{this.state.errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
