/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';

class RequestCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataLoading: ""
    }
  }

  actionClick(tempId, temptype, itemSelect){
    this.setState({dataLoading: itemSelect});

    var url = devconfig.adminV2+"/vendor/process-request";
    var data = JSON.stringify({
      "vendor_id" : tempId,
      "approve" : temptype
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.props.actionSuccess();
        this.setState({dataLoading: ""});
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this)) 
  }

  render() {
    var data = this.props.data;
    var nameSplit = data.name.split(" ");
    var placeholder = "";
    if(nameSplit.length == 1){
      placeholder = nameSplit[0].charAt(0)+nameSplit[0].charAt(1);
    }
    else{
      placeholder = nameSplit[0].charAt(0)+nameSplit[1].charAt(0);
    }
    return (
      <div className="col-lg-6">
        <div className="e-request-item">
          <div className="media">
            <span className="e-request-placeholder">{placeholder}</span>
            <div className="media-body ml-3">
              <h5 className="mb-2">{data.name}</h5>
              <p className="mb-2">Email: {data.email}</p>
              <p>Phone: {data.mobile}</p>
            </div>
          </div>
          <div className="text-right mt-4">
            {
              this.state.dataLoading === "Accept" ?
                <span className="e-p-btn mr-3 e-inActive">Accept</span>
              :
                <span className="e-p-btn mr-3" onClick={this.actionClick.bind(this, data.id, true, "Accept")}>Accept</span>
            }

            {
              this.state.dataLoading === "Reject" ?
                <span className="e-s-btn e-inActive">Reject</span>
              :
                <span className="e-s-btn" onClick={this.actionClick.bind(this, data.id, false, "Reject")}>Reject</span>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default RequestCard;
