/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';

import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';

class CommentReplay extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorMessage: "",
      fBody: "",
      btnClicked: false
    }
  }

  clearStates(){
    this.setState({
      errorMessage: "",
      fBody: "",
      btnClicked: false
    });
  }

  async AddClick(){
    this.props.addSuccess(this.state.fBody);
    $('#e-replayAdd').modal('toggle');
    this.clearStates();
  }

  render() {
    return(
      <div className="modal fade e-modal-wrap" id="e-replayAdd" tabIndex="-1" role="dialog" aria-labelledby="e-replayAddLabel" aria-hidden="true">
        <div className="modal-dialog e-modal-editVendor" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">Add Reply</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Textarea label="Reply" id="f-body" type="text" defaultValue={this.state.fBody} valuechange={(tempValue) => this.setState({fBody: tempValue, errorMessage: ""})}/>
                </div>
                <div className="col-lg-4 mt-3">
                  {
                    this.state.fBody.length && !this.state.btnClicked ?
                      <span className="e-p-btn" onClick={this.AddClick.bind(this)}>Add</span>
                    :
                      <span className="e-p-btn e-inActive">Add</span>
                  }
                </div>
                {
                  this.state.errorMessage.length ?
                    <div className="col-lg-12">
                      <div className="mt-4 text-danger">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CommentReplay;
