/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIGet from '../../components/apiCall/apiGet';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import APIPost from '../../components/apiCall/apiPost';
import FileUploader from '../../components/common/imageUploader';

/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';
import AddVideo from '../../assets/images/video.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';



class AddReport extends Component {
  constructor(props){
    super(props);
    this.state = {
      url: null,
    }
  }
   componentDidMount(){
   this.getProduct();
  }

getProduct=()=>{
    var url = devconfig.vendorV3+"/product/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
      }
    }.bind(this))
  }

  handleSelect(tempVal){
    this.setState({ selectedProduct: Array.isArray(tempVal)  ? tempVal.map(x => x.value) : []});
  }

  hideLogin=()=> {
  $('.e-login3').removeClass('e-login-fly');
  $('#e-log-overlay3').remove();
  }
async fileuploadStatus(status){
  console.log('Insindee fileuploadStatus')
    this.setState({uploading: status})
  }

  fileUploaded(tempData){

    console.log("tempData");
    console.log(tempData);
    this.setState({url: tempData})
    console.log(this.state.url)
  }

  async fileUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-logo-upload").click()
  }
  addReport=()=>{
    var temArry=[]
    temArry.push({file_url: this.state.url})
    console.log(temArry)
    var url = devconfig.vendorV3+"/resources/add-report";
    var data = JSON.stringify({
      "product_ids": this.state.selectedProduct,
      "reports" : temArry,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         this.props.triggerToast(response.message);
        
         
      }
    }.bind(this))
    this.hideLogin();
  }

  hideLogin=()=> {
    $('.e-login3').removeClass('e-login-fly');
    $('#e-log-overlay3').remove();
  }
  render() {
    const options = this.state.products ?
    
        this.state.products.map((item, index) => {
        return(
         { value: item.id, label: item.name }
        );
      })
    :
      null

    return(

      <div className="container text-left e-login3 e-add-product-modal">
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="">Add Reports / Brochures</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
               <span className="e-save e-p-btn float-right" data-toggle="modal" onClick={this.addReport} data-target="#e-addProduct">Save & Add Reports</span>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-lg-6 e-select-product">
                <label>Product</label>
                <SelectBox placeholder="Select the product" options={options} selectType={true} selectchange={(selectedValue) => this.handleSelect(selectedValue)}/>
              </div>
            </div>
             <div className="row pt-3">
              <div className="col-lg-12">
                <label>upload documnets</label>
                <div className="row">
                  <div className="col-lg-4 position-relative">
                  {
                    this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.url ?
                            <span className="e-img-preview">
                              <InputBox value={this.state.url}/>
                              <span className="e-change" onClick={()=>{ this.setState({url: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-doc text-left mb-3" onClick={this.fileUploadTrigger.bind(this, 1)}><span className="mt-2">Click to upload</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <FileUploader 
                    accept="application/pdf, doc/*" 
                    className=""
                    tempKey="/user/address-proof" 
                    id="e-prod-logo-upload" 
                    uploadStatus={this.fileuploadStatus.bind(this)}
                    uploadSuccess={(tempVal, tempData)=> { 
                      console.log('inside');
                      this.setState({url: tempVal}) 
                    }}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>

    )
  }
}

export default AddReport;
