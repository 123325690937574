import React from 'react';
import PropTypes from 'prop-types';

class VideoModal extends React.Component {
  render() {
   

    return (
       <div class="modal fade" id="e-videoplayer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        {this.props.children}
      </div>
    </div>
  </div>
</div>
    );
  }
}



export default VideoModal;