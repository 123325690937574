import React from 'react';
import ReactPlayer from 'react-player/lazy';
import $ from 'jquery';

// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import GetURLVar from '../../components/service/get-url.js'; 
// import Components
import Links from '../../components/navigations/Links';
import Toast from '../../components/popups/Toast';
import EditWebinar from '../../components/popups/EditWebinar';
import DeleteModal from '../../components/popups/confirmationModal';

// import assets
import linkarrow from '../../assets/images/selectArrow/linkarrow.svg';
import Edit from '../../assets/images/resource/edit.svg';
import Delete from '../../assets/images/resource/delete.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import schedule from '../../assets/images/calender.svg'
// import VideoCover from '../../assets/images/resource/videocover.svg';
// import facebook from '../../assets/images/footerIcons/facebook.svg';
// import twitter from '../../assets/images/footerIcons/twitter.svg';
// import linkedin from '../../assets/images/footerIcons/linkdin.svg';
// import whatsapp from '../../assets/images/footerIcons/whatapp.svg';
// import copy from '../../assets/images/footerIcons/icon.svg';
// import schedule from '../../assets/images/resource/webinarschedule.svg';
// import closebtn from '../../assets/images/header/close.png';
// import playbtn from '../../assets/images/slider/carbon_play-outline.svg';

export default class WebinarDetails extends React.Component{
	constructor(props){
		super(props);
		this.state={
			play: true,
			videoPlay: false,
			data: null,
		}
	}
	 pauseVideo=()=>{
	this.setState({play: false});
}
componentDidMount(){
		window.scrollTo({top: 0, behavior: 'smooth'});
		GetURLVar().then(function(query){ 
			this.getData(query.id);
			this.setState({id: query.id});
		}.bind(this));
}

getData(tempId){
		var url = devconfig.vendorV3+"/resources/webinar-detail";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}
	DeleteWebinar=()=>{
   var url = devconfig.vendorV3+"/resources/delete-webinar";
    var data = JSON.stringify({
      "webinar_id" : parseInt(this.state.id),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         window.location.href = '/resources?tab=2';
      }
    }.bind(this))
    
  }
   confirm(){
  	$('#e-confirmModal').modal('toggle');
  }
	editWebinar=()=>{
		var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-edit-webinar').addClass('e-login-fly');
	}
	 openToast=(e)=>{

      this.setState({toast: e , toastType:"success"})
      setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getData(this.state.id);
   

    
  }

	render(){
		return(
			<React.Fragment>
			{
				this.state.data ?
			<React.Fragment>
				<div className="container e-webinar-dtls">
					<div className="col-lg-12 mt-4">
						<Links className="e-marketDetails-link"  activeClassName="" name="Home" url="home"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<Links className="e-marketDetails-link"  activeClassName="" name="Resources" url="resources?tab=1"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<Links className="e-marketDetails-link"  activeClassName="" name="Webinars" url="resources?tab=2"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<span className="e-marketDetails-link-color">{this.state.data.title}</span>
						<div className="text-center col-lg-10 px-0 e-webinar-thump position-relative offset-lg-1 mt-4">
						{
							this.state.videoPlay?
							<ReactPlayer className= "e-resource-video"  id="player" url={this.state.data.link} controls={true} width="100%" />
							:
							<React.Fragment>
							<img src={this.state.data.image} alt="videocover" className="e-resource-video-cover"/>
							<button className="e-video-edit" onClick={this.editWebinar}><img src={Edit}/></button>
							<button className="e-video-delete" onClick={this.confirm}><img src={Delete}/></button>
							{
								this.state.data.type == 2 && this.state.data.source_type ==1 ?
									<button className="e-vidoelist-play-btn" data-toggle="modal" data-target="#videodeatils" onClick={() => this.setState({videoPlay: true})}>
				            <img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
				      	 	</button>
				      	 			:this.state.data.type == 2 && this.state.data.source_type ==2 ?
				      	 	<React.Fragment>
				      	 	<a className="e-vidoelist-play-btn" target="_blank" href={this.state.data.link}>
										<img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
									</a>
				      	 	<span className="e-webinar-time">{this.state.data.scheduled_on}</span>
				      	 	</React.Fragment>
				      	 	:
				      	 	null
										}

									{
										this.state.data.source_type == 2 && this.state.data.type == 1?
											<a className="e-vidoelist-play-btn" target="_blank" href={this.state.data.link}>
												<img className="e-vidoelist-play" src={schedule} alt="playbtn"/>
											</a>
										:
											this.state.data.source_type == 1 && this.state.data.type == 1 ?
												<button className="e-vidoelist-play-btn" data-toggle="modal" data-target="#videodeatils" onClick={() => this.setState({videoPlay: true})} >
													<img className="e-vidoelist-play" src={schedule} alt="playbtn"/>
												</button>
										:
											null
									}
									</React.Fragment>
						}
							
						</div>
					</div>
					<section className="e-video-details-main-sec">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
							<div className="card border-0 p-5 e-videodetails-card">
								<h2 className="e-playstorebanner-head">{this.state.data.title}</h2>
								<p className="mt-3">{this.state.data.description}</p>
								<div className="row no-gutters mt-4">
									<div className="col-lg-6 text-left">
									<span className="mt-3 e-vidoe-list-span mr-3">Smallcase</span> <span className="mt-3 e-vidoe-list-span">Aliceblue</span>
									</div>
									<div className="col-lg-6 text-right">
									<h6 className="e-video-desc">Added on</h6>
										<p className="e-videodate">{this.state.data.added_on}</p>  
										</div> 
								</div>
							</div>
						</div>
					</section>
				{	/*<VideoModal modalId="videodeatils">
					          <button className="e-videomodal-close"data-dismiss="modal" aria-hidden="true" onClick={this.pauseVideo}><img className="e-toggle" src={closebtn} alt="button"/></button>
				      			<ReactPlayer id="player" url={this.state.data.link} playing={this.state.play && this.state.isModalOpen }  width="465px" />
					      	</VideoModal>*/}
				</div>
				{
		      this.state.toast ?
		        <Toast data={this.state.toast} type={this.state.toastType}/>
		     :
		        null
        }
              
				<EditWebinar exData={this.state.data} triggerToast={(tempVal)=>{this.openToast(tempVal)}}/>
				<DeleteModal modalTitle="Confirm Delete" modalContent="Are you sure want to delete "  confirmSuccess={() => this.DeleteWebinar()}/>
			</React.Fragment>
			:
			null
		}
			</React.Fragment>
		)
	}

}