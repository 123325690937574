import React from 'react';
import $ from 'jquery';

import PrimaryButton from '../../components/buttons/primaryBlueButton';
import Input from '../../components/common/inputBox';

export default class EnableWebhook extends React.Component{
	constructor(props){
	super(props);
	this.state={
		 url: this.props.data ? this.props.data : null,
		error: ''
	}
}

confirm=()=>{
	var  linkReg =/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gi;
	 if(this.state.url && !linkReg.test(this.state.url)){
        this.setState({error: "please check url entered in Webhook URL"})
    }else{
    	this.props.save(this.state.url);
    	$('#enbleWebhook').modal('toggle');
    	// this.setState({url: ""})
    }
	
}
	render(){
		return(
			<div className="modal fade e-enable-hook-modal" id="enbleWebhook" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  			<div className="modal-dialog" role="document">
    			<div className="modal-content">
      			<div className="modal-header">
        			<h5 className="modal-title" id="exampleModalLabel">Enable Webhook</h5>
        			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
          			<span aria-hidden="true">&times;</span>
        			</button>
      			</div>
      			<div className="modal-body">
        			<Input placeholder="Enter Webhook URL" value={this.state.url} onChange={(e)=>{this.setState({url: e.target.value, error: ''})}}/>
        			{
              this.state.error.length ?

                  <div className="e-error mb-3">{this.state.error}</div>
              

              :
                null
               }
            
      			</div>
      			<div class="modal-footer">
        			<span class="e-s-btn mr-4" data-dismiss="modal">Close</span>
        			<button type="button" class="e-payout-btn e-p-btn border-0" onClick={this.confirm}>Save</button>
      			</div>
    			</div>

  			</div>
			</div>
		)
	}
}