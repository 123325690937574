/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIGet from '../../components/apiCall/apiGet';
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';
import FaqItem from './faqItem.js';
import FaqAdd from '../../components/popups/faqAdd';
import FaqEdit from '../../components/popups/faqEdit';
import ConfirmationModal from '../../components/popups/confirmationModal';

class FaqMain extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataList: [],
      editItem: [],
      removeId: 0,
      btnClicked: false,
      faqAddReset: true,
      editModalReset: true
    }
  }

  componentWillMount() {
    this.listData();
  }

  listData(){
    
    this.setState({editModalReset: false});

    var url = devconfig.adminV2+"/faq/list";
    
    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataList: response.data, editModalReset: true});
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  editClick(tempItem){
    this.setState({editItem: tempItem});
  }

  clearStates(){
    this.setState({
      dataList: [],
      editItem: [],
      removeId: 0,
      btnClicked: false
    });
  }

  removeProceed(){
    this.setState({btnClicked: true});
    var url = devconfig.adminV2+"/faq/delete";
    var data = JSON.stringify({
      "id" : this.state.removeId
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.clearStates();
        this.listData();
      }
      else{
        this.setState({errorMessage: response.message, btnClicked: false});
      }
    }.bind(this))
  }

  removeModalClick(tempId){
    this.setState({removeId: tempId});
  }

  render() {
    return (
      <div className="container e-main-content e-faq-wrap">
        <div className="row">
          <div className="col-lg-12">
            <div className="">
              <div className="e-conversation-head d-flex justify-content-between">
                <h6>FAQ's</h6>
                <span className="e-conversation-select">
                    <span className="e-p-btn" data-toggle="modal" data-target="#e-faqAdd">Add New</span>
                </span>
              </div>
            </div>
            <div className="e-faq-body">
              {
                Object.keys(this.state.dataList).length ?
                  <div className="col-lg-12">
                    {
                      Object.keys(this.state.dataList).map(function(item, index){
                        var data = this.state.dataList[item];
                        var editModalId = "e-modal-"+data.id;
                        var editModalLink = "#e-modal-"+data.id;
                        return(
                          <div className="e-faq-item" key={index}>
                            <h6>{index+1}. {data.question}</h6>
                            <p>{data.answer}</p>
                            {
                              this.state.btnClicked ?
                                <div className="e-faq-brn-wrap">
                                  <span className="e-p-btn e-inActive mr-3">Edit</span>
                                  <span className="e-s-btn e-inActive">Remove</span>
                                </div>
                              :
                                <div className="e-faq-brn-wrap">
                                  <span className="e-p-btn mr-3" data-toggle="modal" data-target={editModalLink} onClick={this.editClick.bind(this, data)}>Edit</span>
                                  <span className="e-s-btn" data-toggle="modal" data-target="#e-confirmModal" onClick={this.removeModalClick.bind(this, data.id)}>Remove</span>
                                </div>
                            }
                            {
                              this.state.editModalReset ?
                              <FaqEdit id={editModalId} data={data} faqSuccess={this.listData.bind(this)}/>
                              :
                                null
                            }
                            
                          </div>
                        )
                      }.bind(this))
                    }
                  </div>
                :
                  null
              } 
            </div>
          </div>
        </div>
        <FaqAdd faqSuccess={this.listData.bind(this)}/>
        <ConfirmationModal modalTitle="Remove FAQ" modalContent="Are you sure want to remove this faq? Click proceed to confirm." confirmSuccess={this.removeProceed.bind(this)}/>
      </div>
    );
  }
}

export default FaqMain;
