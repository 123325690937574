 /*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

/*Assets and Components*/
import profile from '../../assets/images/profile-01.png';
import logo from '../../assets/images/vendor-logo.svg';

import devconfig from '../../config/config.js';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      logoutdDown: false,
      activeItem: "",
      profileData: null
    }
  }

  componentWillMount(){
    this.findActiveItem();

    if(localStorage.getItem("profileData")){
      var tempData = $.parseJSON(localStorage.getItem("profileData"));
      this.setState({ profileData: tempData});
    }
  }

  dropDownClick(){
    this.setState({ logoutdDown: !this.state.logoutdDown });
  }

  logoutClick(e){
    localStorage.clear();
    window.location.href = '/';
  }

  findActiveItem(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    var newUrl = tempUrl[tempUrl.length-1].split("?");

    this.setState({activeItem: newUrl[0]});
  }


  render() {
    return (
      <div>
        <header className="e-header e-header-nav">
          <div className="container pt-2">

            <div className="row">
              <div className="col-lg-3">
                <a className="" href="/">
                  <img className="e-logo e-full-width" src={logo} alt="Alice blue trade store admin"/>
                </a>
              </div>
              <div className="col-lg-6 text-left e-nav-item-wrap">
                {
                  this.state.activeItem === "" ?
                    <a className="e-nav-item e-text-underline e-active" href="/">Products</a>
                  :
                    <a className="e-nav-item e-text-underline" href="/">Products</a>
                }

                {
                  this.state.activeItem === "profile" || this.state.activeItem === "requests"  ?
                    <a className="e-nav-item e-text-underline e-active" href="/profile">Profile</a>
                  :
                    <a className="e-nav-item e-text-underline" href="/profile">Profile</a>
                }


                {
                  this.state.activeItem === "support-chats" ?
                    <a className="e-nav-item e-text-underline e-active" href="/support-chats">Chat & Support</a>
                  :
                    <a className="e-nav-item e-text-underline" href="/support-chats">Chat & Support</a>
                }

                {
                  this.state.activeItem === "subscription" ?
                    <a className="e-nav-item e-text-underline e-active" href="/subscription">Subscriptions</a>
                  :
                    <a className="e-nav-item e-text-underline" href="/subscription">Subscriptions</a>
                }

                {
                      this.state.activeItem === "resources" ?
                        <span className="dropdown">
                          <a className="e-nav-item e-text-underline e-active dropbtn">Resources &#9662;</a>
                          <div className="dropdown-content">
                            <Link className="hi" to="/resources?tab=1" onClick={()=>{window.location.href="/resources?tab=1"}}>
                                <span onClick={()=>{this.setState({activeItem: "resources"})}}>Videos</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=2" onClick={()=>{window.location.href="/resources?tab=2"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Webinars</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=3" onClick={()=>{window.location.href="/resources?tab=3"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Reports</span>
                            </Link>



                          </div>
                        </span>

                      :

                        <span className="dropdown">
                          <a className="e-nav-item e-text-underline  dropbtn">Resources &#9662;</a>
                          <div className="dropdown-content">
                            <Link className="hi" to="/resources?tab=1"  onClick={()=>{window.location.href="/resources?tab=1"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Videos</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=2" onClick={()=>{window.location.href="/resources?tab=2"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Webinars</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=3" onClick={()=>{window.location.href="/resources?tab=3"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Reports</span>
                            </Link>

                          </div>
                        </span>



                    }


                </div>
                <div className="col-lg-3 text-right pt-4">

                {
                  this.state.profileData ?
                    <a className="e-nav-user-wrap e-card-transition" onClick={this.dropDownClick.bind(this)}>

                      <span className="e-header-shortname mr-2">{this.state.profileData.short_name}</span>
                      {this.state.profileData.name}
                    </a>
                  :
                    <a className="e-nav-user-wrap e-card-transition mt-4" onClick={this.dropDownClick.bind(this)}>
                      <span className="e-nav-user"></span>
                      Vendor
                    </a>
                }

                {
                  this.state.logoutdDown ?
                    <span>
                      <span className="e-logout-dDown-wrap" onClick={this.logoutClick.bind(this)}>logout</span>

                      <span className="e-logout-dDown-overlay" onClick={this.dropDownClick.bind(this)}></span>
                    </span>
                  :
                    null
                }
                </div>
              </div>
            </div>

            {/*<nav className="navbar navbar-dark sticky-top">
              <a className="navbar-brand col-lg-6 mr-0 e-font-15 e-f-weight-600 e-align-center" href="/">
                <img className="e-logo e-full-width e-m-right-10" src={logo} alt="Alice blue trade store admin"/>
              </a>
              <ul className="navbar-nav px-3 col-lg-6">
                <li className="nav-item text-nowrap">

                  <a className="e-sidebar-list-item e-underline" href="/">
                    <span className="nav-item-sub active">Trade Store</span>
                  </a>

                  <a className="e-sidebar-list-item e-underline" href="/">
                    <span className="nav-item-sub active">Trade Store</span>
                  </a>

                  <a className="e-sidebar-list-item e-underline" href="/">
                    <span className="nav-item-sub active">Trade Store</span>
                  </a>
                  <span onClick={this.dropDownClick.bind(this)}>
                    <img className="e-profile-img e-full-width e-m-left-30" src={profile}/>
                    {
                      !this.state.logoutdDown ?
                        <span onClick={this.dropDownClick.bind(this)} className="e-down-arrow-icon e-icon-hover e-down-arrow-icon-custom e-m-left-10"></span>
                      :
                        <span onClick={this.dropDownClick.bind(this)} className="e-down-arrow-icon-active e-icon-hover e-down-arrow-icon-custom e-m-left-10"></span>
                    }

                    {
                      this.state.logoutdDown ?
                        <span className="e-logout-dDown-wrap">
                          <a onClick={this.logoutClick.bind(this)} href="/" className="e-c-primary e-underline e-font-12 e-m-right-30">Logout</a>
                          <span className="e-logout-icon e-icon-hover"></span>
                        </span>
                      :
                        null
                    }
                  </span>
                </li>
              </ul>
            </nav>*/}

        </header>
      </div>
    );
  }
}

export default Header;
