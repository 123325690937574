import React from 'react';

// import asset from files
import loader from '../../assets/images/loader/page-overlay-loader.gif';
function OverlayLoader(){
	return(
		<div className="e-page-overlay-loader">
      <img className="e-page-loader" src={loader} alt="Loader"/>
    </div>
	)
}
export default OverlayLoader;