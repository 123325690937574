/*Import Packages*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';


/*Import components*/
import AddArticle from '../../components/popups/addArticle';
import ConfirmationModal from '../../components/popups/confirmationModal';
import Modal from '../../components/modal/VideoModal';

// import assets
import videothumb2 from '../../assets/images/resource/videothumb2.svg';
import videothumb from '../../assets/images/resource/videothumb1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import VideoAdd from '../../assets/images/video-icon.svg';
import review from '../../assets/images/review.svg';




function BlogListing (props) {

   let history = useHistory();
  function RouteChange() {
    let path = `blogDetails?id=`+ props.data.id +`&product=` +props.data.title;
    history.push(path);
  }

    return(

          <div className="col-lg-4  mt-4">
          {
            props.data ?
            <div className="card border-0 e-card-hover e-videolist-card e-blog" onClick={RouteChange}>
              <div className="card-image e-video-card-headersec">
                <img className="e-videolist-thumb"   id="video-cover" src={props.data.image} alt="Video title"/>

                 {/*<button id="play" className="e-vidoelist-play-btn">
                  <img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
                </button>
                 <span className="e-vidoe-duration"> 2.00</span>*/}
              </div>
              <div className="card-content p-3">
                
                <h5 className="e-video-card-head pb-4">{props.data.title}</h5>
                <p className="e-webinar-p">{props.data.indroduction}</p>
                <div className="">
                 {
                props.data.products && props.data.products.map((item, index) =>{
                  return(
                    <span className="e-mrktplce-usrtype mr-2">{item.name}</span> 
                  );
                })
              }
                </div>
                <div className="row pt-4 no-gutters">
                  <div className="col-lg-5">
                    <span className="e-webinar-span">{props.data.published_on}</span>
                  </div>
                  <div className="col-lg-7 text-right">
                    <span className="e-write-review d-inline-block">Read more<img src={review} className="pl-3"/></span>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
          </div>
       




      );

}

export default BlogListing;
