/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/

class VendorCard extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  cardClick(tempID){
    this.props.cardClickSuccess(tempID);
  }
  
  render() {
    var data = this.props.data;
    var nameSplit = data.name.split(" ");
    var placeholder = "";
    if(nameSplit.length == 1){
      placeholder = nameSplit[0].charAt(0)+nameSplit[0].charAt(1);
    }
    else{
      placeholder = nameSplit[0].charAt(0)+nameSplit[1].charAt(0);
    }
    return (
      <div className="col-lg-6">
        <div className="e-request-item e-card-transition" onClick={this.cardClick.bind(this, data.id)}>
          <div className="media">
            <span className="e-request-placeholder">{placeholder}</span>
            <div className="media-body ml-3">
              <h5 className="mb-2">{data.name}</h5>
              <p className="mb-2">Email: {data.email}</p>
              <p>Phone: {data.mobile}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VendorCard;
