
/*Package importing*/
import React, { Component } from 'react';
import { useHistory  } from 'react-router-dom';

/*Assets and Components*/


// import components

import ConfirmationModal from '../../components/popups/confirmationModal';
import AvgReview from '../../components/startRating/avgRating';
// import ProductDetail from '../../container/detail/main.js';

// import assets


function ProductCard (props){

  let history = useHistory();


  function productDetails() {
    let path = `product-details?id=`+props.data.id;
    history.push(path);
    // alert('hii');
    // return <Redirect to='/product-details'  />
  }
   function productDelete() {
    let path = `product-details?id=`+props.data.id+`&delete=true`;
    history.push(path);
    // alert('hii');
    // return <Redirect to='/product-details'  />
  }
  

    return (
      <div className="e-marketplace-allcard-sec">
        <div className="card e-marketDetails-card border-0">
          <div className="e-card-head e-abcard-hed p-3" style={{backgroundImage: props.bgColor}}>
            <img className="card-logo" src={props.data.logo}  alt="Card image cap"/>
            <img className="card-illustration" src={props.data.illustration} alt="Card image cap"/>
          </div>
          <div className="e-card-rating">
          {/*<span className="e-fill-star"></span>
          <span className="e-half-fill-star"></span>
          <span className="e-no-fill-star"></span>*/}
          {
            props.data.review_count ?
              <div className="e-prcard-rating">
              <AvgReview rating={props.data.rating}/> <span className="e-prcard-review-count">({props.data.review_count} Reviews)</span>
              </div>
            :
              null

          }
          
        </div>
          <div className="card-body">
            <h5 className="e-mrktplc-card-title pb-3">{props.data.title}</h5>
            <h5 className="e-mrktplc-card-title pb-3">{"Product ID: "+props.data.product_id}</h5>
            <p className="e-mrktplc-card-text">{props.data.description}</p>
            <div className="row pl-3">
            {
          props.data.filters.map((item, index) => {
            return(
              <span className="e-card-type-tag" key={index}>{item.name}</span>
            );
          })
        }
          </div>
            <p className="mt-4 e-mrktplc-card-p"><span className="e-mrktplc-card-title">Price : &#8377; {props.data.price}</span> <span className="e-mrktplc-product-plan">/ per {props.data.price_validity_text}</span></p>
            {
          props.data.offer ?
            <p>
              <span className="e-mrktplc-card-title">Offer Price: </span> 
              <span className="e-mrktplc-card-title e-prdt-free-grn">
                {
                  props.data.offer_text && props.data.offer_text != "Free" && props.data.offer_text != "Free Trail" ?
                    <React.Fragment>&#8377; </React.Fragment>
                  :
                    null
                }
                {props.data.offer_text ? props.data.offer_text : props.data.offer_price}
              </span> 
              <span className="e-mrktplc-product-plan">
                {props.data.offer_validity_text.length ? " / per "+props.data.offer_validity_text : null}
              </span>
            </p>
          :
            null
        }
            
            <div className="row">
              <div className="col-lg-6">
                <span className="e-p-btn e-product d-inline-block" onClick={productDetails}>View More Details</span>
              </div>
             {/* <div className="col-lg-6">
                             <span className="e-r-btn d-inline-block" onClick={productDelete}>Remove Product</span>
                           </div>*/}
            </div>
          </div>
        </div>
        <ConfirmationModal/>
      </div>

    );

}

export default ProductCard;
