/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


// import service
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
// import GetURLVar from '../../services/apiCall/getParams/get-url.js';
/*Assets and Components*/
import devconfig from '../../config/config.js';
import SelectBox from '../../components/common/selectBox';
import NoDataFound from '../../components/common/noData';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';

import noResultImg from '../../assets/images/no-result.png';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large.js';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
class tradeStoreTab extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      selectedSort: {value: 6, label: "Created desc"},
      selectedProduct: {value: undefined, label: "All"},
      selectedPartner: {value: undefined, label: "All"},
      selectedLeadStatus: {value: undefined, label: "All"},
      productFilterDefault: {value: undefined, label: "All"},
      partnerFilterDefault: {value: undefined, label: "All"},
      sortDefault: {value: 6, label: "Created desc"},
      leadDefault: {value: 0, label: "New"},
      leadStatusDefault: {label: "All", value: undefined},
      pageNum: 1,
      productFilters: [],
      partnerFilters: [],
      data: [],
      listData: [],
      errorMessage: [],
      itemToEdit: {},
      initalDate: null,
      startDate: null,
      endDate: null,
      dateFilter: false,
      filterChange: false,
    }
  }

  componentDidMount(){
    // this.getFilters();
    this.getData();
    this.getProduct();
    // this.downloadCSV();
    $(window).scroll(
        async () => {
          if (
            $(window).scrollTop() + $(window).height() >
            $(document).height() - 400
          ) {
            await this.setState({
              pageNum: this.state.pageNum + 1
            });
            await this.getData()
          }
        }
      );


  }

  getData =()=>{
    if(this.state.dateFilter){
  	var start_date_temp = this.state.startDate;
      var end_date_temp = this.state.endDate;
      if(this.state.startDate && this.state.endDate){
      var start_date = start_date_temp.format("DD-MM-YYYY");
      var end_date = end_date_temp.format("DD-MM-YYYY");
      start_date_temp.format("dd-mm-yyyy");
      end_date_temp.format("dd-mm-yyyy");
    }
  }
    else{
      var start_date = undefined
      var end_date = undefined
    }
		var url = devconfig.vendorV3+'/subscription/list';
    var data = JSON.stringify({
       sort_by: this.state.selectedSort.value,
          page_num: this.state.pageNum,
          product_id: this.state.selectedProduct.value,
          start_date: start_date,
          end_date: end_date,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        // this.setState({listData:response.data, loading: false});
         var temdta = [];

        if(this.state.listData.length && !this.state.filterChange ){
          temdta = this.state.listData;
          temdta = $.merge(temdta, response.data);
        }
        else{
          temdta = response.data;
        }
         this.setState({listData:temdta, loading: false, filterChange: false});
			}
      else{
        this.setState({ loading: false,  filterChange: false});
      }
    }.bind(this))
	}

    getProduct=()=>{
    var url = devconfig.vendorV3+"/product/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
      }
    }.bind(this))
  }


  productChange = async (product) =>{
  	await this.setState({selectedProduct: product, loading: true, pageNum: 1, data: []});
    await this.getData(this.state.selectedSort.value);
    // await this.downloadCSV(this.state.selectedSort.value);
  }
  sortChange = async (selectedSort) => {
    await this.setState({selectedSort: selectedSort, loading: true, pageNum: 1, data: [], filterChange: true});
    await this.getData();
    // await this.downloadCSV();
  }


  onDatesChange = async ({ startDate, endDate }) => {
    if(startDate && endDate){
      var fromDate = startDate.format("YYYY-MM-DD");
      var toDate = endDate.format("YYYY-MM-DD");
    }
      await this.setState({
        startDate,
        endDate,
        fromDate: fromDate,
        toDate: toDate,
        data: [],
        pageNum: 1,
        loading: true,
        dateFilter: true,
        filterChange: true
      });

      await this.getData();
      // await this.downloadCSV();
    
  }




  render() {
    // const papaparseOptions = {
    //   header: true,
    //   dynamicTyping: true,
    //   skipEmptyLines: true,
    //   transformHeader: header =>
    //     header
    //       .toLowerCase()
    //       .replace(/\W/g, '_')
    // }
    // const { mainCatOption } = this.state;
    const sortOptions = [
      {value: 1, label: "Expiring soon"},
      {value: 2, label: "Subscription date asc"},
      {value: 3, label: "Subscription date desc"},
      {value: 4, label: "New first"},
      {value: 5, label: "Old first"},
    ];
   	const productOptions = this.state.products ?

        this.state.products.map((item, index) => {
        return(
         { value: item.id, label: item.name }
        );
      })
    :
      null

    // var productFilters = this.state.productFilters;
    // var partnerFilters = this.state.partnerFilters;
    return (
      <div className="e-main-content e-subscription-wrap">
        <div className="container">


          <div className="row mt-4 pt-2">
            <div className="col-lg-4">
              <div className="e-filter-wrap pt-2">
                <label>Date</label>
              </div>
              <DateRangePicker
                startDate={this.state.startDate}
                endDateId="e-endDate"
                startDateId="e-startDate"
                endDate={this.state.endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                startDatePlaceholderText={"Start date"}
                endDatePlaceholderText={"End date"}
                disableScroll={false}
                hideKeyboardShortcutsPanel={true}
                regular={false}
                small
                numberOfMonths={1}
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              />
            </div>
          { /* <div className="col-lg-2">
                        <div className="e-filter-wrap pt-2">
                          <label>All Products</label>
                          <SelectBox options={productOptions} selectchange={this.productChange} placeholder="All Products"/>
                        </div>
                      </div>*/}
            <div className="col-lg-6"></div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Sort by:</label>
                <SelectBox defaultValue={this.state.sortDefault} options={sortOptions} selectchange={this.sortChange} placeholder="Sort"/>
              </div>
            </div>

          </div>
          </div>








         


        <div className="container e-tradestore-table">
          {
            this.state.loading?
              <div className="row">
                <div className="col-lg-12 text-center pt-5">
                	<LazyLoaderGif/>
                </div>
              </div>
            :
              <div className="row mt-4">
              {
                this.state.listData && this.state.listData.length ?
                  <div className="col-md-12">
                    <table className="table e-subscription-table-wrap table-striped e-tradestore-tab">
                      <thead>
                        <tr>
                          <th>Client ID</th>
                          <th>Client Name</th>
                          <th>Product</th>
                          <th>Email</th>
                          <th>Plan</th>
                          <th>Subscr. Start</th>
                          <th>Subscr. End</th>
                          <th>Amount</th>
                          <th>Transaction ID</th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.listData.map((item, index)=>{
                            return(
                              <tr key={index}>
                                <td className="e-s-text">{item.alice_blue_id}</td>
                                <td className="text-capitalize">{ item.name ? item.name.toLowerCase() : "--"}</td>
                                <td className="e-subscription-product">{item.product_name}</td>

                                <td>
                                  <a href={"mailto:" + item.email} target="_blank">
                                    {item.email}
                                  </a>
                                </td>
                                <td>{item.free ? "Free" : item.is_trail ? "Trial" : !item.free && !item.is_trail ? "Paid" : null }</td>

                                <td className="text-capitalize e-s-text">{item.purchased_on && item.purchased_on != "N/A" ? item.purchased_on : "--"}</td>
                                <td className="text-capitalize e-s-text">{item.expired_on && item.expired_on != "N/A" ? item.expired_on : "--"}</td>
                                 <td>{item.total}</td>
                                  <td>{item.transaction_id}</td>

                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                :
                  <NoDataFound data="No subscriptions found."/>
              }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default tradeStoreTab;
