/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Radio from '@material-ui/core/Radio';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import ImageUploader from '../../components/common/imageUploader';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import Time from '../../components/common/Time'; 
import DatePicker from '../../components/common/datePicker';

// services
import GetURLVar from '../../components/service/get-url.js'; 


/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';



class EditWebinar extends Component {
  constructor(props){
    super(props);
    this.state = {
      products: null,
      selectedProduct: [],
      title: this.props.exData.title,
      description: this.props.exData.description,
      scheduledTime: null,
      scheduledDate: null,
      duration: null,
      upcomingWebinar: this.props.exData.link,
      image: this.props.exData.image,
      type: this.props.exData.type,
      sourcetype: this.props.exData.source_type,
      date: this.props.exData.scheduled_on,
    }
  }

  componentDidMount(){
    var tempid=[]
   this.getProduct();
   GetURLVar().then(function(query){ 
      this.setState({webinarId: query.id});
    }.bind(this));

    
    console.log(tempid);
  }

  getProduct=()=>{
    var url = devconfig.vendorV3+"/product/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
      }
    }.bind(this))
  }

  EditWebinar=()=>{
    if(this.state.scheduledDate && this.state.scheduledTime !== null){
    this.setState({date: this.state.scheduledDate+ " " +this.state.scheduledTime.format('HH:mm')});
  }
    var url = devconfig.vendorV3+"/resources/edit-webinar";
    var data = JSON.stringify({
      "webinar_id" : this.state.webinarId,
      "product_ids": this.state.selectedProduct,
      "title": this.state.title,
      "description" : this.state.description,
      "webinar_type" : this.state.type,
      "scheduled_on" : this.state.date,
      "source_type" : this.state.sourcetype,
      "duration" : this.state.duration,
      "link" : this.state.upcomingWebinar,
      "image"  : this.state.image,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.triggerToast(response.message);
        this.hideLogin();
      }
      else{
        this.setState({error: response.message, apiLoading: false}) 
      }
    }.bind(this))
      
  }
  handleSelect(tempVal){
    this.setState({ selectedProduct: Array.isArray(tempVal)  ? tempVal.map(x => x.value) : []});
  }
  handleRadio(e){
     this.setState({type: e.target.value});
  }
  handleSource(e){
     this.setState({sourcetype: e.target.value});
  }
  async uploadStatus(status){
    this.setState({uploading: status})
  }

  logoUploaded(tempData){
    console.log("tempData");
    console.log(tempData);
    this.setState({image: tempData})
  }

  async logoUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-logo-upload").click()
  }

  hideLogin=()=> {
  $('.e-edit-webinar').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }


  render() {

    const options = this.state.products ?
    
        this.state.products.map((item, index) => {
        return(
         { value: item.id, label: item.name }
        );
      })
    :
      null
      this.state.defaultSelect = this.props.exData.products? 
    this.props.exData.products.map((item, index) =>{
      return({ value: item.id, label: item.name });
    })
    :
    null
    this.state.selectedProduct= this.props.exData.products? 
    this.props.exData.products.map((item, index) =>{
      return(
        item.id
        );
    })
    :
    null

    return(

      <div className="container text-left e-edit-webinar e-add-product-modal">
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="e-edit-head">Edit webinar</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
              { 
                this.state.selectedProduct[0] &&
                this.state.title &&
                this.state.description 
                 ?
               <span className="e-save e-p-btn float-right" data-toggle="modal" onClick={this.EditWebinar}  data-target="#e-addProduct">Update webinar</span>
               :
               <span className="e-save e-p-btn float-right e-btn-inactive" >Update webinar</span>
              }
              {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6"> 
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12 e-select-product">
                <label>Product</label>
                <SelectBox placeholder="Select the product" options={options} defaultValue={this.state.defaultSelect} selectType={true} selectchange={(selectedValue) => this.handleSelect(selectedValue)}/>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12 e-select-product">
                <label>Webinar Tite</label>
                <InputBox placeholder="Title" value={this.state.title} onChange={(e) => {this.setState({title: e.target.value})}}/>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-lg-12">
                <label>Short Description</label>
                <Textarea placeholder="Short Description.." value={this.state.description} onChange={(e) => {this.setState({description: e.target.value})}}/>
              </div>
            </div>
            <div className="row pt-1 no-gutters">
              <div className="col-lg-2">
                <h6 className="mt-2 e-upcoming">Upcoming Webinar?</h6>
              </div>
              <div className="col-lg-10 text-left">

                <label className="e-radio-label mr-5">
                  <Radio value= {1}  checked={this.state.type == 1} onChange={this.handleRadio.bind(this)}/> Yes
                </label>
                <label className="e-radio-label">
                 <Radio value= {2}  checked={this.state.type == 2} onChange={this.handleRadio.bind(this)}/> No
                </label>
              </div>
            </div>
            <div className="row pt-1 no-gutters">
              <div className="col-lg-2">
                <h6 className="mt-2 e-upcoming">Source Type</h6>
              </div>
              <div className="col-lg-10 text-left">

                <label className="e-radio-label mr-5">
                  <Radio value= {1}  checked={this.state.sourcetype == 1} onChange={this.handleSource.bind(this)}/> Youtube
                </label>
                <label className="e-radio-label">
                 <Radio value= {2}  checked={this.state.sourcetype == 2} onChange={this.handleSource.bind(this)}/> Goto Webinar 
                </label>
              </div>
            </div>
            <div className="row pt-3">
            {
              this.state.type == 1 ?
              <React.Fragment>
              <div className="col-lg-7">
                <label>schedule details</label>
                <div className="row pb-3">
                  <div className="col-lg-6">
                   <DatePicker className="e-webinar-date" value={this.state.scheduledDate} dateChange={(e)=> {this.setState({scheduledDate: e})}} outsideRange={true}/>
                    
                  </div>
                  <div className="col-lg-6">
                    <Time
                              placeholder={" Time"}
                              showSecond={false}
                              onChange={(e) => {this.setState({scheduledTime: e}); }}
                              time={this.state.scheduledTime} />
                  </div>
                </div>
              
              </div>
              </React.Fragment>
              :
              <React.Fragment>
              <div className="col-lg-7 pt-3">
                <Time
                      placeholder={" Expected Duration"}
                      showSecond={true}
                      onChange={(e) => {this.setState({duration: e}); }}
                      time={this.state.duration} />
              </div>
              <div className="col-lg-7 pt-3">
                <InputBox placeholder=" Webinar Link" value={this.state.upcomingWebinar} onChange={(e) => {this.setState({upcomingWebinar: e.target.value})}}/>
              </div>
              </React.Fragment>
            }
            </div>
            <div className="row pt-3">
              <div className="col-lg-4">
                <label>upload screenshots / images</label>
                <div className="row">

                  <div className="col-lg-8 position-relative">
                  {
                    this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.image ?
                            <span className="e-img-preview">
                              <img src={this.state.image}/>
                              <span className="e-change" onClick={()=>{ this.setState({image: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-btn e-store text-center mb-3" onClick={this.logoUploadTrigger.bind(this, 1)}><span className="mt-2">Add image</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader 
                    accept="application/pdf, image/*" 
                    className="hidden"
                    tempKey="/user/address-proof" 
                    id="e-prod-logo-upload" 
                    uploadStatus={this.uploadStatus.bind(this)}
                    uploadSuccess={this.logoUploaded.bind(this)}
                  />
                  </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    )
  }
}

export default EditWebinar;
