import React from 'react';
import { NavLink } from 'react-router-dom';

function Links(props){
	return(
		<NavLink className={props.className} to={props.url} activeClassName={props.activeClassName}>{props.name} </NavLink>
	)

}

export default Links;