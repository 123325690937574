import React from 'react';
import $ from 'jquery';

// import service
import GetURLVar from '../../components/service/get-url.js'; 
// import components
import SelectBox from '../../components/common/selectBox';
import Links from '../../components/navigations/Links';
import PendingReview from '../../components/card/pendingReview';
import ReviewCard from '../../components/card/ReviewCard';
import review from '../../assets/images/review.svg';
import All from '../../assets/images/all.png';
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';
import NothingFound from '../../components/common/noData';
import ApiLoader from '../../components/Loaders/ApiLoader';
// import Pagination from '../..components/pagination/pagination.js';

// import assets
import star from '../../assets/images/rating/star.svg';
const options = [
			
      { value: 1 , label: 'Newly Added' },
      { value: 2, label: 'Most positive' },
      { value: 3, label: 'Least positive' },
      { value: 4, label: 'Hidden' },

    ];

export default class Review extends React.Component{
	 constructor(props) {
    	super(props);
    	this.state = {
      		isOpen: false,
					star: 0,
					end: 8,
					showPerPage: 8,
					pageNum: 1,
					pendingReview: false,
					openPending: false,
					closeReview: true,
    	};
  	}
  	componentDidMount(){
		GetURLVar().then(function(query){ 
			this.getData(query.id);
			this.setState({id: query.id});
		}.bind(this));
		
	}

	getData(tempId){
		this.setState({dataLoading: true, data: null});
		var url = devconfig.vendorV3+"/product/reviews";
    var data = JSON.stringify({
      "id": parseInt(tempId),
      "page_num": parseInt(this.state.pageNum),
      "sort_by" : parseInt(this.state.sortIndex),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}

	getPendngs(tempId){
		this.setState({pendingLoading: true, pendingData: null, openPending: true, closeReview: false});
		var url = devconfig.vendorV3+"/product/pending-reviews";
    var data = JSON.stringify({
      "id": parseInt(tempId),
      "page_num": parseInt(this.state.pageNum),
      "sort_by" : parseInt(this.state.sortIndex),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({pendingData:response.data, pendingLoading: false});
      }
      else{
        this.setState({ pendingLoading: false});
      }
    }.bind(this))
	}

	async pageNumChange(tempVal){
		await this.setState({pageNum: tempVal});
		this.getData(this.state.id);
	}
	async handleSort(tempSort){
		await this.setState({ sortIndex: tempSort.value, pageNum: 1});
  	this.getData(this.state.id);
	}

  	 toggleModal = () => {
	    $('.e-review-modal').addClass('e-modal-show');
	    var overlaylogin = '<div class="e-modal-overlay" id="e-modal-overlay"></div>';
	    $("body").append(overlaylogin);
	    document.getElementById('e-modal-overlay').addEventListener('click', this.removeOverlay);
  	}
  	removeOverlay = () =>{
    $('.e-review-modal').removeClass('e-modal-show');
    $('#e-modal-overlay').remove();
  }

	render(){

		return(
		<section className="e-marketDetailsReview-sec">
		{
						this.state.data && this.state.data.pending_review_count != 0 ?
						<div className="col-lg-12 text-right">
					<span className="e-write-review ml-5 pl-5" onClick={()=>{this.getPendngs()}}>Approve Reviews<img src={review} className="pl-3"/></span>
					
				</div>
				:
				null
								}
		
		{
					this.state.data && this.state.data.reviews.length && this.state.closeReview ?
			<div className="row e-rating-review p-3">
				<div className="col-lg-2">
					<p className="e-ts-rating pt-4">Trade Store Rating</p>
					<h1 className="">{this.state.data.total_rating}</h1>
					<p><span className="e-write-review">{this.state.data?
										this.state.data.total_review_count :
										null} Reviews</span></p>
				</div>
				<div className="col-lg-4 col-md-4 col-sm-8 col-12">
									{
										
										this.state.data && Object.keys(this.state.data.rating_data).length ?
										<React.Fragment>
										{
											Object.keys(this.state.data.rating_data).map(
		                    function(key, index) {
		                      return (
		                        <div>
															<p className="e-rview-pbar">
																<span>{key}</span> <img src={star} alt="rating star" className="mr-1"/>
															 	<progress className="e-ratingbar" id="rating" value={this.state.data.rating_data[key]} max="100"></progress> <span>{this.state.data.rating_data[key]}</span>
															</p>
														</div>
		                      );
		                    }.bind(this)
		                  )
										}
										</React.Fragment>
										:
										null
									}
								</div>
				<div className="offset-2 col-lg-4">
					<div className="pt-5 mt-3"><SelectBox className="d-inline-block e-filter-select" options={options} selectchange={(tempVal) => this.handleSort(tempVal)} defaultValue={{ label: "Recent", value: "helpful" }} /></div>
				</div>
				
				
			</div>
				:
					null
				}

			<div className="col-lg-12">
					{
					this.state.openPending?
					<div className="row">
						<span className="e-write-review" onClick={()=>{this.setState({closeReview: true, openPending: false,})}}><img src={All} className="pr-3"/>All Reviews </span>
					</div>
						:
						null
					}
					<div className="row">
						{
							this.state.dataLoading ?
							<div className="col-lg-12 text-center">
								<ApiLoader/>
							</div>
							:
							null
						}

						{
							this.state.data && this.state.closeReview ?
			        this.state.data.reviews.map((item, index) => {
			          return(
			            <div className="col-lg-4 col-md-4 mb-3" key={index}>
										<ReviewCard data={item} reload={()=>{this.getData(this.state.id)}}/>
									</div>
			          );
			        })
			        :
			        null
			      }   
			      {
							this.state.pendingData && this.state.openPending ?
			        this.state.pendingData.reviews.map((item, index) => {
			          return(
			            <div className="col-lg-4 col-md-4 mb-3" key={index}>
										<PendingReview data={item} reload={()=>{this.getData(this.state.id)}}/>
									</div>
			          );
			        })
			        :
			        null
			      } 
		      	{

		      		!this.state.dataLoading && this.state.data && !this.state.data.reviews.length ?
		      		<div className="col-lg-12 text-center mt-5">
		      			<NothingFound data="No Reviews found! Please try later."/>
		      		</div>
		      		:
		      		null

		      	}
		      {
		      
		      		      		!this.state.dataLoading  && this.state.openPending ?
		      		      		<div className="col-lg-12 text-center mt-5">
		      		      			<NothingFound data="No Pending Reviews found."/>
		      		      		</div>
		      		      		:
		      		      		null
		      
		      		      	}

		      {/*  <div className="col-lg-12 text-center">
		      		      			      	{
		      		                    	this.state.data && this.state.data.no_of_pages > 1 ?
		      		                    		<Pagination pageNum={this.state.data.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
		      		                    	:
		      		                    		null
		      		                    }
		      		      			      </div>  
		      		      			    */}
					</div>
				</div>


			{/*<ReviewModal/>*/}

		</section>
	)
	}

}

 