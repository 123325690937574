/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import NewRequestPending from './newRequest';
import DeletePending from './deleteRequest';
import EditBank from '../../components/popups/editBank';
import Toast from '../../components/popups/Toast';
import ApiModal from '../../components/modal/apiDetails';
import EnableWebhook from '../../components/modal/enableWebhook';

import bank from '../../assets/images/bank.svg';
import copy from '../../assets/images/icons/icon.svg';

class RequestsMain extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeMenu: 1,
      detailID: null
    }
  }

  componentWillMount() {
    this.getProfile();
    this.getPaymentHist();
  }

 getProfile(){
  var url = devconfig.vendorV2+"/auth/get-profile";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({profile: response.data, detailID: response.data.id});
         // this.setState({menuOpen: true});
      }
    }.bind(this))
 }
 getPaymentHist(){
  var url = devconfig.vendorV3+"/auth/payout-list";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({history: response.data});
         // this.setState({menuOpen: true});
      }
    }.bind(this))
 }
 enableAPI=()=>{
  var url = devconfig.vendorV3+"/product/subscription-access";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.detailID),
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({token: response.data.API_ACCESS_TOKEN});
        this.triggerToast('API Enabled successfully');
        $('#apidetails').modal('toggle');
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
 }

 enableWebhook=(temp)=>{
  var url = devconfig.vendorV3+"/auth/update-webhook-url";
    var data = JSON.stringify({
      "webhook_url" : temp
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({toast: "Successfully Enabled Webhook" , toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
          this.getProfile();
      }
      else{
        this.setState({toast: "Failed to Enabled Webhook" , toastType:"fail"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
    }.bind(this))
 }

 edit(){
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-edit-bank').addClass('e-login-fly');

  }
  triggerToast=(e)=>{
    this.setState({toast: e , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getProfile();
  }


  render() {
    return (
      <React.Fragment>
      {
      this.state.profile ?
     <div className="container e-main-content pt-4">
       <div className="row">
        <div className="col-lg-6 mb-3">
          <h5 className="e-profile-head">Profile</h5>
        </div>
        <div className="col-lg-6 mb-3 text-right">
          {
            this.state.profile && this.state.profile.subscription_access ?
              <p>Subscriptions list api is enabled, <button className="e-enable-btn ml-0 pl-0" data-toggle="modal" data-target="#apidetails">Click to view access token.</button></p>
            :
              <button className="e-enable-btn" onClick={this.enableAPI}>Enable Subscriptions API Now</button>
          }
        
        </div>
      </div>
        <div className="row pb-5">
         <div className="col-lg-6">
            <div className="card border-0 e-profile-card pt-4">
              <div className="row">
                <div className="col-lg-2 mt-3 text-center">
                  <span className=" e-header-shortname">{this.state.profile.short_name}</span>
                </div>
                <div className="col-l6-10">
                  <h6>{this.state.profile.name}</h6>
                  <p className="mt-3 e-profile-phone">Email: {this.state.profile.email}</p>
                  <p className="">Phone: {this.state.profile.mobile}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card border-0 e-profile-card pt-4">
              <div className="row">
                <div className="col-lg-2 mt-2 text-center">
                  <img src={bank}/>
                </div>
                {
                  this.state.profile.bank_info.account_added ?
                    <div className="col-l6-10">
                      <h6>{this.state.profile.bank_info.bank}</h6>
                      <p className="mt-3 e-profile-phone">Account no: {this.state.profile.bank_info.account_no}</p>
                        <p className="">IFSC: {this.state.profile.bank_info.ifsc_code}</p>
                    </div>
                  :
                    <div className="col-l6-10">
                      <h6>You have not added bank details yet. </h6>
                      <button className="e-add-product-btn e-p-btn mt-3 e-add-bank-details-btn" onClick={this.edit}>Click to add bank details</button>
                    </div>
                }

              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-lg-2">
            <div className="card border-0 e-profile-card py-4">
              <h5 className="e-paid-card">Total Puchase Amount</h5>
              <div className="mt-3 text-right e-card-amount">₹ {this.state.profile.total_amount_purchased}</div>
            </div>
          </div>
           <div className="col-lg-2">
            <div className="card border-0 e-profile-card py-4">

              <h5 className="e-paid-card">Aliceblue Commision</h5>
              <div className="mt-3 text-right e-card-amount">% {this.state.profile.ab_commission}</div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card border-0 e-profile-card py-4">
              <h5 className="e-paid-card">Commision amount</h5>
              <div className="mt-3 text-right e-card-amount">₹ {this.state.profile.total_ab_commission}</div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card border-0 e-profile-card py-4">
              <h5 className="e-paid-card">Total Pending</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.vendor_amount}</div>
            </div>
          </div>
           <div className="col-lg-2">
            <div className="card border-0 e-profile-card py-4">
              <h5 className="e-paid-card">Total Received</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.total_paid}</div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card border-0 e-profile-card py-4">
              <h5 className="e-paid-card">Pending amount</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.payout_balance}</div>
            </div>
          </div>
          
        </div>
        <div className="row">
          <div className="col-lg-6 mt-5">
          {
            !this.state.profile.webhook_url ? 
            <span className="e-add-product-btn e-p-btn mt-5" data-toggle="modal" data-target="#enbleWebhook">Enable webhook URL.</span>
            :
            <React.Fragment>
            <p className="">Webhook URL is <a href={this.state.profile.webhook_url} target="_blank">{this.state.profile.webhook_url}</a> </p>
            <span className="e-add-product-btn e-p-btn mt-5" data-toggle="modal" data-target="#enbleWebhook">Edit Webhook URL.</span>
            </React.Fragment>
          }
          </div>
        </div>
        <div className="pt-5 pb-5">
        <table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th>Transaction Id</th>
              <th>Paid Amount</th>
              <th>Paid On</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {
            this.state.history?
            this.state.history.map((item, index) =>{
              return(
                <tr key={index} className="py-5">
              <td>{item.transaction_id == null ? "--": item.transaction_id }</td>
              <td>{item.amount}</td>
              <td>{item.date}</td>

              <td className={item.payment_status ? "e-green" : "e-red"}>{item.payment_status ? "Success" : "failed"}</td>
            </tr>
              )
            })
            :
            null
          }
          </tbody>
        </table>
        </div>
        <EditBank data={this.state.profile.bank_info} confirmSuccess={(temp)=>{this.triggerToast(temp)}}/>
        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }

        <EnableWebhook data={this.state.profile.webhook_url} save={(temp)=>{this.enableWebhook(temp)}}/>

        <ApiModal>
          <div className="mb-4">
            Access Token: <p className="e-accesstoken">{this.state.profile.latest_token}</p>
            <a className="mr-3 e-ftricon-link" onClick={() => {
              this.setState({ linkcopy: true});
              navigator.clipboard.writeText(this.state.profile.latest_token);
              setTimeout(
                function() {
                  this.setState({ linkcopy: false });
                }
                .bind(this),
                1500
              );
            }
          }><img src={copy} alt="icon" className="e-footer-icon"/></a>

          {
            this.state.linkcopy ?
              <span className="e-link-copied">Token copied</span>
            :
              null
          }
          </div>
          <div>
          Api URL: <a className="d-block" href="https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v3/data/subscriptions/vendor-subscriptions" target="_blank">https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v3/data/subscriptions/vendor-subscriptions</a>
          </div>
          <div className="mt-3">
            <p>Api Type: <b>POST</b></p>
          </div>
          <div className="mt-3">
            Api Data:
            <code className>
              {" data: {start_date : null, end_date : null, product_id : null, sort_by : null}"}
            </code>
          </div>
        </ApiModal>


       </div>


         :
        null
      }




    </React.Fragment>
    );
  }
}

export default RequestsMain;
