/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SelectBox from '../../components/common/selectBox';
import ProductCard from '../../components/card/productCard';
// import ProductRequestCard from '../../components/card/productRequestCard';
import NoDataFound from '../../components/common/noData';
import ApiLoader from '../../components/Loaders/ApiLoader';
// import OverlayLoader from '../../components/Loaders/OverlayLoader';
import AddProduct from '../../components/popups/addProduct';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import InputBox from '../../components/common/inputBox';
// import NewRequestPending from './newRequest';
// import DeletePending from './deleteRequest';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import GetURLVar from '../../components/service/get-url.js';
import Toast from '../../components/popups/Toast';

/*Services*/
import devconfig from '../../config/config.js';


class cardList extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      cardList:[],
      product: [],
      typeFilterOption: [],
      expFilterOption: [],
      typeFilterDefault: { value: null, label: 'All Products' },
      expFilterDefault: { value: null, label: 'All' },
      pageNum: 1,
      typeFilterValue: null,
      vendorFilterValue: null,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      modalFilterTypeOptions: [],
      modalFilterLevelOptions: [],
      tabIndex: 1,
      message: true,
      modalClass: false,
      filterChange: false,
    }
  }

  componentDidMount(){
    this.getProduct(this.state.pageNum,  this.state.typeFilterValue);
    // this.getProductRequest();
  }

  addProduct=()=> {
    // var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    // $("body").append(overlaylogin);
    // $('.e-login').addClass('e-login-fly');

    this.setState({modalClass: 'e-login-fly'});
  }

  getProduct=(tempPage, tempFilter)=>{

    this.setState({dataLoading: true});
    var url = devconfig.vendorV3+"/product/list";
    var data = JSON.stringify({
      "page_num": parseInt(tempPage),
      "filter_id" : parseInt(tempFilter),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
          var temdta = [];

        if(this.state.product.length && !this.state.filterChange ){
          temdta = this.state.product;
          temdta = $.merge(temdta, response.data.products);
        }
        else{
          temdta = response.data. products;
        }


         this.setState({product: temdta, productData: response.data, dataLoading: false, filterLoading: false, lazyLoadStatus: false});
          console.log(this.state.product)
         if(response.data.length <= 19){
          this.setState({lazyDataEnd: true});
        }
        else{
          this.setState({lazyDataEnd: false});
        }

         var tempArry = [];
        tempArry.push({ value: null, label: 'All Products' });
        response.data.filter_data.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempArry.push(item);
          // tempModalArry.push(item);
        });
        this.setState({category: tempArry});

        //  var tempvendor = [];
        // tempvendor.push({ value: null, label: 'All Products' });
        // response.data.product_creators.map(function(item, index){
        //   var item = {value: item.id, label:item.name};
        //   tempvendor.push(item);
        //   // tempModalArry.push(item);
        // });
        // this.setState({listVendors: tempvendor});
      }
      else{
        this.setState({dataLoading: false, filterLoading: false, lazyLoadStatus: false});
      }
    }.bind(this))
  }



  searchChange(e){
    this.setState({searchKeyword: e.target.value});
    if(!e.target.value.length){
      this.getProduct(1, null);
    }
    if(e.target.value.length >= 2){
      this.searchAPI(e.target.value);
    }
  }

  searchAPI(tempText){
    this.setState({dataLoading: true});
    var url = devconfig.adminV3+"/product/search";
    var data = JSON.stringify({
      "text": tempText,
    })
    APIPost(url, data).then(function (response) {
      if(response.status){
        this.setState({ product: response.data, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }

  selectTypeChange(tempData){
    this.getProduct(this.state.pageNum, tempData.value, );
    this.setState({ typeFilterValue: tempData.value, filterChange: true});
  }

  addModalClose(){
    this.setState({modalClass: false});
    this.getProduct(1, null);
  }

  // addModalClose(){
  //   this.setState({modalClass: false, filterChange: true});
  //   this.getProduct(1, null, null);
  // }

  loadMoreClick(){
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
    this.getProduct(this.state.pageNum + 1, this.state.typeFilterValue);
  }



  vendorDetailClick(tempId){
    window.location.href = '/vendors?id='+tempId;
  }
  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  triggerToast =(tempMsg) =>{
    this.setState({modalClass: false, filterChange: true});
    this.setState({toast: "We will notify the admin regarding the product, the product will reflect on user app once admin approve it.",  toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getProduct(1, null);
  }
  render() {
    const { mainCatOption } = this.state;

    const options = [
      { value: 'today', label: 'All Products' },
      { value: 'sevendays', label: 'Salalah, Oman' },
      { value: 'onemonth', label: 'Dubai, UAE' }
    ]



    return (
      <div className="container e-main-content e-dash-cardList-wrap pt-4">
        <div className="row">
          <div className="col-lg-8 e-Product-tab-link-wrap">

                <span className="e-tabs-item e-active pr-4">Products</span>



          </div>

          <div className="col-lg-4 pt-2">
            <div className="text-right mb-3">
              <span className="e-add-product-btn e-p-btn" onClick={this.addProduct.bind(this)}>+ Add New Product</span>
            </div>
          </div>
        </div>


            <div>
              <div className="row pt-3">
                <div className="col-lg-2">
                  <div className="e-filter-wrap pt-2">
                    <label>Category</label>
                    <SelectBox options={this.state.category}  placeholder="All Products" selectchange={ this.selectTypeChange.bind(this)}/>
                  </div>
                </div>


              </div>
              <div className="row">
                <div className="col-md-12">
                  {
                    this.state.dataLoading && !this.state.lazyLoadStatus ?
                      <div className="row mt-4">
                        <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                        <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                      </div>
                    :
                      <span>

                            <div>
                              <div className="row e-p-top-20">
                                {
                                 this.state.product?
                                 this.state.product.map((item, index) =>{
                                  // linear-gradient( to top right, #ff5000,#ffb017)
                                  var tempBgColor = "linear-gradient( to top right, "+item.gradient_1+" , "+item.gradient_2+")"
                                    return(
                                      <div className="col-lg-4 col-md-6 col-sm-12 col-12  e-card-outer-wrap e-card-transition pb-3" key={index}>
                                        <ProductCard data={item} bgColor ={tempBgColor}/>
                                      </div>
                                    );
                                 })
                                :
                                 null
                                }
                              </div>
                              {
                                !this.state.lazyDataEnd && this.state.productData  ?
                                  <div className="row">
                                    <div className="col-lg-12 e-load-more-wrap pb-5">
                                      <span className="e-s-btn" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                                    </div>
                                  </div>
                                :
                                  null
                              }
                              {
                                this.state.lazyLoadStatus ?
                                  <div className="row">
                                    <div className="col-lg-12 e-load-more-wrap pb-5 text-center">
                                      {/*<OverlayLoader/>*/}
                                    </div>
                                  </div>
                                :
                                  null
                              }
                            </div>



                      </span>
                  }
                  {
                    !this.state.dataLoading && this.state.product && !this.state.product.length?
                     <div className="row">
                              <NoDataFound data="No data found."/>
                            </div>:
                            null
                  }
                </div>
              </div>
              <AddProduct modalClass={this.state.modalClass} modalClose={this.addModalClose.bind(this)} success={(temp)=>this.triggerToast(temp)}/>
            </div>
            {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }



      </div>
    );
  }
}

export default cardList;
