import React from 'react';
import Select from 'react-select';
/*Import custom components and assets*/
class SelectBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    console.log("defaultValue");
    console.log(this.props.defaultValue);
    this.setState({ selectedValue: this.props.defaultValue});
  }
  selectChange = selectedValue => {
    this.setState({ selectedValue });
    this.props.selectchange(selectedValue)
  };
  render() {
    const {selectedValue} = this.state;
    return (
      <Select
        isSearchable={false}
        options={this.props.options}
        value={selectedValue}
        onChange={this.selectChange}
        isMulti={this.props.selectType}
        menuIsOpen={this.props.menuIsOpen}
        placeholder={this.props.placeholder}/>

    );
  }
}
export default SelectBox;