/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';
import ProductCard from '../../components/card/productCard';
import VendorProfileEditModal from '../../components/popups/vendorProfileEdit';
import ConfirmationModal from '../../components/popups/confirmationModal';

class VendorDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataProfile: null,
      dataProduct: null
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();
    this.listData(query.id);
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  listData(tempID){
    var url = devconfig.adminV2+"/vendor/products";
    var data = JSON.stringify({
      "vendor_id" : tempID
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({dataProfile: response.data.profile, dataProduct: response.data.products});
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }


  suspendVendor(){
    var url = devconfig.adminV2+"/vendor/block";
    var data = JSON.stringify({
      "vendor_id" : this.state.dataProfile.id
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        var query = this.getUrlVars();
        this.listData(query.id);
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  unblockVendor(){
    var url = devconfig.adminV2+"/vendor/unblock";
    var data = JSON.stringify({
      "vendor_id" : this.state.dataProfile.id
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        var query = this.getUrlVars();
        this.listData(query.id);
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  backClick(){
    window.location.href = '/vendors';
  }

  render() {
    return (
      <div className="col-lg-9">
        {
          this.state.dataProfile ?
            <div className="e-partnered-wrap mb-5">
              <span className="e-vendorDetail-back" onClick={this.backClick.bind(this)}> Back to All Vendors</span>
              <div className="e-vendorDetail-wrap mt-3">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="media">
                      <span className="e-request-placeholder">{this.state.dataProfile.short_name}</span>
                      <div className="media-body ml-3">
                        <h5 className="mb-2">{this.state.dataProfile.name}</h5>
                        <p className="mb-2">Email: {this.state.dataProfile.email}</p>
                        <p>Phone: {this.state.dataProfile.mobile}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 e-vendorDetail-btn-wrap text-right">
                    <span className="e-p-btn mr-4" data-toggle="modal" data-target="#e-vendorDetailEdit">Edit</span>
                    {
                      this.state.dataProfile.active ?
                        <span className="e-r-btn" data-toggle="modal" data-target="#e-confirmModal">Suspend</span>
                      :
                        <span className="e-r-btn" data-toggle="modal" data-target="#e-confirmModal">Unblock</span>
                    }
                  </div>
                </div>
              </div>

              {/*Modal*/}
              <VendorProfileEditModal data={this.state.dataProfile}/>
              
              {
                this.state.dataProfile.active ?
                  <ConfirmationModal modalTitle="Suspend Vendor" modalContent="Are you sure want to suspend this vendor? Click proceed to confirm." confirmSuccess={this.suspendVendor.bind(this)}/>
                :
                  <ConfirmationModal modalTitle="Unblock Vendor" modalContent="Are you sure want to Unblock this vendor? Click proceed to confirm." confirmSuccess={this.unblockVendor.bind(this)}/>
              }


            </div>
          :
            null
        }

        {
          this.state.dataProduct ?
            <div className="e-partnered-products">
              <div className="row">
                {
                  this.state.dataProduct.map(function(item, index){
                    var tempBgColor = "linear-gradient( to top right, "+item.gradient1+" , "+item.gradient2+")"
                    return(
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 e-m-bottom-30 e-card-outer-wrap e-card-transition" key={index}>
                        <ProductCard data={item} bgColor={tempBgColor}/>
                      </div>
                    )
                  }.bind(this))
                }
              </div>
            </div>
          :
            null
        }
      </div>
    );
  }
}

export default VendorDetail;
