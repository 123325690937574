/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import InputBox from '../../components/common/inputBox';

import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';
import PhoneValidation from '../validation/phone';

class VendorProfileEditModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorMessage: "",
      vName: "",
      vPhone: "",
      data: [],
      btnClicked: false
    }
  }

  componentWillMount() {
    var data = this.props.data;
    if(data){
      this.setState({
        vName: data.name,
        vPhone: data.mobile
      });
    }
  }

  async updateDetails(){
    var validationSuccess = true;

    await PhoneValidation(this.state.vPhone).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid phone number, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    if(validationSuccess){
      this.setState({btnClicked: true});
      var url = devconfig.vendorV2+"/auth/edit-profile";
      var data = JSON.stringify({
        "name" : this.state.vName,
        "mobile" : this.state.vPhone
      })

      APIPost(url, data).then(function(response){
        if(response.status){
          $('#e-vendorDetailEdit').modal('toggle');
          this.setState({errorMessage: "", btnClicked: false});
          this.props.success();
        }
        else{
          this.setState({errorMessage: response.message, btnClicked: false});
        }
      }.bind(this))
    }
  }

  render() {
    return(
      <div className="modal fade e-modal-wrap" id="e-vendorDetailEdit" tabIndex="-1" role="dialog" aria-labelledby="e-vendorDetailEditLabel" aria-hidden="true">
        <div className="modal-dialog e-modal-editVendor" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">Edit profile details</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <InputBox label="Name" id="v-name" type="text" defaultValue={this.state.vName} valuechange={(tempValue) => this.setState({vName: tempValue, errorMessage: ""})}/> 
                  <InputBox label="Mobile number" id="p-phone" type="text" defaultValue={this.state.vPhone} valuechange={(tempValue) => this.setState({vPhone: tempValue, errorMessage: ""})}/>
                </div>
                <div className="col-lg-4 mt-3">
                  {
                    this.state.vName.length && this.state.vPhone.length && !this.state.btnClicked ?
                      <span className="e-p-btn" onClick={this.updateDetails.bind(this)}>Update</span>
                    :
                      <span className="e-p-btn e-inActive">Update</span>
                  }
                </div>
                {
                  this.state.errorMessage.length ?
                    <div className="col-lg-12">
                      <div className="mt-4 text-danger">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VendorProfileEditModal;
