import React from 'react';

// import asstes
import prevarrow from '../../assets/images/pagination/prev.png';
import nextarrow from '../../assets/images/pagination/next.svg';


export default class Pagination extends React.Component{
  constructor(props){
    super(props);
    this.state={
      currentActive: 1
    }
  }
  componentDidMount() {
    this.setState({currentActive: this.props.currentPage});
  }

  nextPage =()=>{
    this.props.change(this.state.currentActive + 1)
    this.setState({currentActive: this.state.currentActive + 1});
  }

  prevPage =()=>{
    this.props.change(this.state.currentActive - 1)
    this.setState({currentActive: this.state.currentActive - 1});
  }

  paging =(tempVal) =>{
    this.props.change(tempVal)
    this.setState({currentActive: tempVal});
  }
   
  render(){
    return(
      <ul className="e-pagination mt-5 pl-0">
        <li className="e-previous-page">
          <button className="e-pagin-prev" disabled={this.state.currentActive == 1}  onClick={this.prevPage}>
            <img src={prevarrow} alt="arrow"/>
          </button>
        </li>
        {
          [...Array(this.props.pageNum)].map((item, index) => {
            return(
              <li key={index} className={this.state.currentActive == index+1 ? "e-pagin-active" :""} >
                <span className="e-pagin-number" value={index+1} onClick={() => this.paging(index+1)}>{index+1}</span>
              </li>
            );
          })
        }
        <li  className="e-next-page">
          <button className="e-pagin-next" disabled={this.state.currentActive == this.props.pageNum } onClick={this.nextPage}>
            <img src={nextarrow} alt="arrow"/>
          </button>
        </li>
      </ul>
    )
  }
}
