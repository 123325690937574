/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


/*Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

class ImageUploader extends Component {
  constructor(props){
    super(props);
    this.state = {
      file: "",
    }
  }

  

  async uploadDocument(e) {
    this.props.uploadStatus(true)
    if(e.target.files.length){
      var tempFileName = e.target.files[0].name;
      var fileReader = new FileReader();

      var tempKey = this.props.tempKey

      if (e.target.files != null) {
        if(this.props.imageSelected){
          this.props.imageSelected();
        }
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onloadend = async function (event) {
          var file = event.target.result;
          var url = devconfig.adminV1 + "/utils/upload-image";
          var data = JSON.stringify({
            'file': file,
            'fileName': tempFileName,
            'key': tempKey
          })
          APIPost(url, data).then(async function(response){
            if(response.status){

              await this.setState({ file: response.cdn_url});
              this.props.uploadSuccess(response.cdn_url, JSON.parse(data));
            }
            else{
              await this.setState({ apiLoading: false});
            }
            await this.props.uploadStatus(false)
          }.bind(this))
        }.bind(this)
      }
    }
  }

  render() {
    return (
      <input 
        id={this.props.id} 
        className={this.props.className} 
        type="file" 
        accept={this.props.accept} 
        onChange={this.uploadDocument.bind(this)}
        {...this.props}
      />
    );
  }
}

export default ImageUploader;
