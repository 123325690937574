import React from 'react';
import $ from 'jquery';


// import service
import APIGet from '../../components/apiCall/apiGet';
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';

// import component
import DeleteModal from '../../components/popups/confirmationModal';
// import assets
import downloadpdf from '../../assets/images/pdfdownload.svg';
import Delete from '../../assets/images/delete-product.svg';

export default class ReportListing extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }

  DeleteReport=(e)=>{
   var url = devconfig.vendorV3+"/resources/delete-report";
    var data = JSON.stringify({
      "report_id" : parseInt(e),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         window.location.href = '/resources?tab=3';

      }
    }.bind(this))
    
  }

  confirm=(e)=>{
    $('#e-confirmModal').modal('toggle');
  }
  render(){
  return(
      <div className="col-lg-4 mt-4">
      {
        this.props.data ?

          <div className="card  e-report-card border-0">
          <div className="row px-2 py-3">
            <div className="col-lg-8">
            <h5 className="text-left ml-2 e-report-head">{this.props.data.file_name}</h5>
            </div>
            <div className="col-lg-4 text-right">
              <button className="e-report-delete" onClick={this.confirm}><img src={Delete}/></button>
              <a href={this.props.data.file_url} target="_blank" className=" e-pdf-download">PDF <img src={downloadpdf} alt="image"/></a>
            </div>
          </div>
          </div>
          :null
        }
          <DeleteModal modalTitle="Delete" modalContent="Do you want to delete this report?" confirmSuccess={()=>{this.DeleteReport(this.props.data.id)}}/>
        </div>
        
       
  )
}
}
