/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import devconfig from '../../config/config.js';
import APIPost from '../apiCall/apiPost';
import PasswordValidation from '../validation/password';
import logo from '../../assets/images/logo-orange.png';
import InputBox from '../../components/common/inputBox';

class PasswordReset extends Component {
  constructor(props){
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
      email: null,
      key: null
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();
    if(query.email && query.key){
      this.setState({
        email: query.email,
        key: query.key
      });
    }
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  async signUpClick(e){
    if(this.state.newPassword != this.state.confirmPassword){
      this.setState({errorMessage: "Your password and confirmation password do not match"});
    }
    else{
      var validationSuccess = true;

      await PasswordValidation(this.state.newPassword).then(function(response){
        if(!response){
          this.setState({errorMessage: "Password containing at least 8 characters, one number, and one letter."});
          validationSuccess = false;
        }
      }.bind(this))

      if(validationSuccess){
        var url = devconfig.vendorV2+"/auth/reset-forgot-password";
        var data = JSON.stringify({
          "email" : this.state.email,
          "password": this.state.newPassword,
          "key" : this.state.key
        })
        APIPost(url, data).then(function(response){
          if(response.status == "ok"){
            window.location.href = '/?reset=true';
          }
          else{
            this.setState({errorMessage: response.message});
          }
        }.bind(this))
      }
    }
  }

  newPassChnange(e) {
    this.setState({newPassword: e.target.value, errorMessage: ""});
  }
  confirmPassChange(e) {
    this.setState({confirmPassword: e.target.value, errorMessage: ""});
  }

  render() {
    return (
      <div className="container-fluid e-login-outer-wrap">
        <div className="row">
          <div className="offset-md-1 col-md-4 e-bg-white e-login-panel e-p-equal-30 e-border-5 e-m-top-100">
            <div>
              <img className="e-logo e-full-width e-m-right-10 e-login-logo" src={logo}/>
            </div>
            <div className="e-p-top-20">
              <h2 className="e-card-p-txt">Create <br/><span>Your Password</span></h2>
            </div>
            <div className="e-p-top-50">
              <dl className="ev-input-content-wrap e-m-bottom-20">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  {/*<input id="u-email" className="u-email" type="password" onChange={this.newPassChnange.bind(this)} value={this.state.newPassword} required/>
                  <label htmlFor="u-email" className="label u-email">New Password</label>*/}

                  <InputBox id="u-new" className="u-email" placeholder="New Password"
                    value={this.state.newPassword}
                    onChange={(e)=>{this.setState({newPassword: e.target.value})}} type="password" />
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  {/*<input id="u-password" onChange={this.confirmPassChange.bind(this)} value={this.state.confirmPassword} className="u-password" type="password" required/>
                  <label htmlFor="u-password"  className="label u-email">Confirm Password</label>*/}
                  <InputBox id="u-password" className="u-password" placeholder="New Password"
                    value={this.state.confirmPassword}
                    onChange={(e)=>{this.setState({confirmPassword: e.target.value})}} type="password" />
                </dd>
              </dl>
            </div>
            <div className="e-p-top-30">
              {
                this.state.newPassChnange != "" && this.state.confirmPassword != "" && this.state.email && this.state.key ?
                  <span className="e-p-btn e-login-btn" onClick={this.signUpClick.bind(this)}>Submit</span>
                :
                  <span className="e-p-btn e-login-btn e-btn-inactive">Submit</span>
              }

            </div>
            {/*<div className="e-p-top-30">
              <a className="e-font-12 e-f-weight-500 e-underline" href="/">Forgot Password</a>
            </div>*/}
            <div className="col-lg-12 e-p-top-10 e-p-left-0">
              <p className="e-f-weight-500 e-c-red pt-3">{this.state.errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordReset;
