/*Package importing*/
import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import SelectBox from '../../components/common/selectBox';
import firebase from 'firebase';
import database from 'firebase';


class Chatbox extends Component {
  constructor(props){
    super(props);
    this.state = {
      moreClick: false,
      convoId: null,
      chats: [],
      reply: "",
      initalConvoId: 0
    }
  }

  componentDidMount() {
    this.getMessage(this.props.item.convoId);
    this.setState({initalConvoId: this.props.item.convoId});
  }

  componentDidUpdate() {
    if(this.state.initalConvoId != this.props.item.convoId){
      this.setState({initalConvoId: this.props.item.convoId});
      this.getMessage(this.props.item.convoId);
    }
  }

  getMessage(tempId){
    firebase.database().ref("messages").child(tempId).orderByChild("Time").on('value', function(snapshot) {
      if(snapshot.val()){
        var data = [];

        snapshot.forEach(snap => {
          var chat = snap.val();
          data.push(chat);
        });
        this.setState({loading: false, chats: data});

        setTimeout(
          function() {
            $('.e-chat-body').animate({scrollTop: $('.e-chat-body').prop("scrollHeight")}, 500);
          }
          .bind(this), 200
        );

      }
    }.bind(this));
  }
  

  moreClick(){
    this.setState({moreClick: !this.state.moreClick});
  }

  dateToFromNowDaily( myDate ) {
    // get from-now for this date
    var fromNow = moment( myDate ).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay:  '[Yesterday]',
      sameDay:  '[Today]',
      nextDay:  '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  }

  replyChange(e){
    this.setState({reply: e.target.value});
  }

  keyDownEvent(e){
    if(e.key == "Enter" && e.shiftKey ){

    }
    else{
      if(e.key == "Enter"){
        this.sendMessage(this.state.reply);
      }
    }
  }

  sendMessage(tempText){
    var messageRef = firebase.database().ref("messages").child(this.props.item.convoId);
    var chatsRefUser = firebase.database().ref("chats").child(this.props.item.sender_id).child(this.props.item.convoId);
    var chatsRefAdmin = firebase.database().ref("chats").child("admin").child(this.props.item.convoId);

    let time = firebase.database.ServerValue.TIMESTAMP;
    let reverseTime = 0 - Number(new Date().getTime());

    var supportType= "General Support"
    if(!this.props.item.admin){
      supportType = this.props.item.productName + " Support";
    }

    chatsRefUser.update({
      last_message: tempText,
      last_messageBy: "admin",
      receiver_id: this.props.item.sender_id,
      sender_id: "admin",
      sender_image: "https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png",
      receiver_image: this.props.item.sender_image,
      sender_name: supportType,
      receiver_name: this.props.item.sender_name,
      updated: time,
      reverseTime: reverseTime,
      seen: false,
      convoId: this.props.item.convoId,
      deleted: false,
      createdAt: String(new Date()),
    });

    chatsRefAdmin.update({
      last_message: tempText,
      last_messageBy: this.props.item.sender_name,
      receiver_id: "admin",
      sender_id: this.props.item.sender_id,
      sender_image: this.props.item.sender_image,
      receiver_image: "https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png",
      sender_name: this.props.item.sender_name,
      receiver_name: supportType,
      updated: time,
      reverseTime: reverseTime,
      seen: true,
      convoId: this.props.item.convoId,
      deleted: false
    });

    messageRef.push({
      created: time,
      reverseTime: reverseTime,
      message: tempText,
      sender_id: "admin",
      convoId: this.props.item.convoId,
      _id: 2,
      text: tempText,
      createdAt: new Date(),
      user: {
        _id: 2,
        name: supportType,
        avatar: "https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png",
      }
    });

    this.setState({reply: ""});
  }

  clearTicket(){
    this.closeChat();
  }

  async closeChat(){

    var timestamp = new Date().getTime();
    var newConvoId = this.props.item.convoId + "_closed_" + timestamp;

    var chatRef = firebase.database().ref("chats").child(this.props.item.sender_id).child(this.props.item.convoId);
    var chatRefAdmin = firebase.database().ref("chats").child("admin").child(this.props.item.convoId);

    var newChatRefUser = firebase.database().ref("chatsClosed").child(this.props.item.sender_id).child(newConvoId);
    var newChatRefAdmin = firebase.database().ref("chatsClosed").child("admin").child(newConvoId);

    var convoRef = firebase.database().ref("messages").child(this.props.item.convoId);
    var newMessageRef = firebase.database().ref("messages").child(newConvoId);

    
    await chatRef.update({
      closed: true,
      closedOn: moment().format(),
      closedTimestamp: timestamp
    })

    await chatRefAdmin.update({
      closed: true,
      closedOn: moment().format(),
      closedTimestamp: timestamp
    })

    await chatRef.once("value", (snapshot)=>{
      newChatRefUser.update(snapshot.val())
      newChatRefAdmin.update(snapshot.val())
    })

    await chatRef.remove()
    await chatRefAdmin.remove()

    await convoRef.once("value", (snapshot)=>{
        newMessageRef.update(snapshot.val())
    })

    await convoRef.remove()              
  }

  render() {  

    return (
      <div className="e-chat-wrap">

        {
          this.props.item.closed ?
            <div className="e-chat-head-wrap d-flex justify-content-between">
              {
                this.props.item.admin ?
                  <span className="e-chat-head">Topic: <b>General Support</b></span>
                :
                  <span className="e-chat-head">Topic: <b>{this.props.item.productName} {this.props.item.alice_blue_id}</b></span>
              }
            </div>
          :
            <div className="e-chat-head-wrap d-flex justify-content-between">
              {
                this.props.item.admin ?
                  <span className="e-chat-head">Topic: <b>General Support</b></span>
                :
                  <span className="e-chat-head">Topic: <b>{this.props.item.productName}</b></span>
              }
              
              
            </div>
        }

        {
          this.state.chats.length ?
            <div className="e-chat-body">
              {
                this.state.chats.map(function(item, index){
                  
                  var nameSplit = item.user.name.split(" ");
                  var placeholder = "";
                  if(nameSplit.length == 1){
                    placeholder = nameSplit[0].charAt(0)+nameSplit[0].charAt(1);
                  }
                  else{
                    placeholder = nameSplit[0].charAt(0)+nameSplit[1].charAt(0);
                  }
                  if(item._id === 1){
                    return(
                      <div className="e-chat-item e-chat-user" key={index}>
                        <span className="e-chat-pic">{placeholder}</span>
                        <span className="e-chat-message">{item.text}</span>
                        {
                          index === this.state.chats.length-1 ?
                            <div className="e-chat-time">{this.dateToFromNowDaily(item.createdAt) + " " + moment(item.createdAt).format("HH:mm")}</div>
                          :
                            null
                        }
                      </div>
                    )
                  }
                  else{
                    return(
                      <div className="e-chat-item e-chat-admin d-flex flex-row-reverse" key={index}>
                        <span className="e-chat-pic"></span>
                        <span className="e-chat-message">{item.text}</span>
                        {
                          index === this.state.chats.length-1 ?
                            <div className="e-chat-time">{this.dateToFromNowDaily(item.createdAt) + " " + moment(item.createdAt).format("HH:mm")}</div>
                          :
                            null
                        }
                      </div>
                    );
                  }
                  
                }.bind(this))
              }

            </div>
        :
          null
        }
        
        {
          this.props.item.closed ?
            <div className="e-chat-footer">
              <p className="e-closed-txt">This ticket closed on {this.dateToFromNowDaily(this.props.item.closedOn) + " " + moment(this.props.item.closedOn).format("HH:mm")}</p>
            </div>
          :
            <div className="e-chat-footer">
              <input placeholder="Type a message" type="text" value={this.state.reply} onChange={this.replyChange.bind(this)} onKeyDown={this.keyDownEvent.bind(this)} />
            </div>
        }
        
      </div>
    );
  }
}

export default Chatbox;
