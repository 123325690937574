/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import EmailValidation from '../../components/validation/email';
import PhoneValidation from '../../components/validation/phone';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

class AddVendor extends Component {
  constructor(props){
    super(props);
    this.state = { 
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true,
      dataList: []
    }
  }

  componentWillMount() {
    this.listInvitation();
  }

  listInvitation(){
    var url = devconfig.adminV2+"/vendor/signup-pending";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataList: response.data});
      }
    }.bind(this))
  }

  async inviteVendor(){
    var validationSuccess = true;

    await EmailValidation(this.state.vEmail).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid email address, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    await PhoneValidation(this.state.vPhone).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid phone number, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    if(validationSuccess){
      this.setState({btnClicked: true});
      var url = devconfig.adminV2+"/vendor/invite";
      var data = JSON.stringify({
        "name": this.state.vName,
        "email": this.state.vEmail,
        "mobile": parseInt(this.state.vPhone)
      })

      APIPost(url, data).then(function(response){
        if(response.status){
          this.setState({resetInput: false});
          this.clearStates();
          this.listInvitation();
        }
        else{
          this.setState({errorMessage: response.message, btnClicked: false});
        }
      }.bind(this))
    }
  }

  clearStates(){
    this.setState({
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true
    });
  }

  resendClick(tempId,tempSelector, e){

    $(tempSelector).html("Sending")
    $(tempSelector).addClass("e-sending")

    var url = devconfig.adminV2+"/vendor/resent-invite";
    var data = JSON.stringify({
      "vendor_id" : tempId
    })

    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        $(tempSelector).html("Success")
        $(tempSelector).addClass("e-success")
      }
      else{
        $(tempSelector).html("Failed")
        $(tempSelector).addClass("e-failed")
      }
    }.bind(this))
  }

  render() {
    return (
      <div className="col-lg-9">
        <div className="e-invite-vendor-wrap mb-5">
          <h2 className="mb-4">Add New Vendor</h2>
          <div className="row">
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox label="Vendor name" id="v-name" type="text" defaultValue={this.state.vName} valuechange={(tempValue) => this.setState({vName: tempValue, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox label="Vendor email" id="v-email" type="text" defaultValue={this.state.vEmail} valuechange={(tempValue) => this.setState({vEmail: tempValue, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox label="Vendor phone" id="v-phone" type="text" defaultValue={this.state.vPhone} valuechange={(tempValue) => this.setState({vPhone: tempValue, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            {
              this.state.errorMessage ?
                <div className="col-lg-12">
                  <div className="mt-1 mb-3  text-danger">
                    {this.state.errorMessage}
                  </div>
                </div>
              :
                null
            }
          </div>
          {
            this.state.vName.length && this.state.vEmail.length && this.state.vPhone.length && !this.state.btnClicked ?
              <span className="e-p-btn" onClick={this.inviteVendor.bind(this)}>Invite Vendor</span>
            :
              <span className="e-p-btn e-inActive">Invite Vendor</span>
          }
        </div>

        {
          this.state.dataList.length ?
            <div className="e-invite-vendor-wrap">
              <h2 className="mb-4">Invitation Acceptance Pending</h2>
              <div className="row">
                <div className="col-lg-12">
                  <table id="example" className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.dataList.map(function(item, index){
                          var spanClass = "e-s-btn e-resend-"+item.id;
                          var spanSelector = ".e-resend-"+item.id;
                          return(
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile}</td>
                              <td>
                                <span className={spanClass} onClick={this.resendClick.bind(this, item.id, spanSelector)}>Resend</span>
                              </td>
                            </tr>
                          )
                        }.bind(this))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          :
            null
        }
      </div>
    );
  }
}

export default AddVendor;
