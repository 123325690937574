// const url= "http://localhost:5000";
// const url= "https://unt6he2pxf.execute-api.ap-south-1.amazonaws.com/prod/api/v1";


{/*Production link */}
const urlV1= "https://3vyyzk8vv6.execute-api.ap-south-1.amazonaws.com/v1/api/v1";
const urlV2= "https://3vyyzk8vv6.execute-api.ap-south-1.amazonaws.com/v1/api/v2";


// const urlV1= "https://9d4eritqz9.execute-api.ap-south-1.amazonaws.com/dev/api/v1";
// const urlV2= "https://9d4eritqz9.execute-api.ap-south-1.amazonaws.com/dev/api/v2";

// const baseUrl = "https://eggqh64ytc.execute-api.ap-south-1.amazonaws.com/dev3/";
const baseUrl = "https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/";

const devV1 = baseUrl+"api/v1";
const devV2 = baseUrl+"api/v2";
const devV3 = baseUrl+"api/v3";

  

var devconfig = {
	adminV1: devV1 + "/admin",
	vendorV1: devV1 + "/vendor",
	vendorV2: devV2 + "/vendor",
	vendorV3: devV3 + "/vendor",
    // vendorV1: urlV1 + "/vendor",
    // adminV1: urlV1 + "/admin",
    // vendorV2: urlV2 + "/vendor",
    // adminV2: urlV2 + "/admin",
    // urlOnly: urlV1,
   
}

module.exports = devconfig;