
import React, { Component } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount(){
    if(this.props.value){
      this.setState({ date: this.props.value});
    }
  }

  render() {
    return (
      <div>
        <SingleDatePicker
          date={this.state.date} // momentPropTypes.momentObj or null
          // onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
          onDateChange={(date) => { 
            this.setState({date: date})
            this.props.dateChange(date.format("DD-MM-YYYY"))
          }} 
          className={this.props.className}
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
          placeholder="Select date"

          isOutsideRange={(day) =>
              this.props.outsideRange && !isInclusivelyBeforeDay(day, moment())
            }
            
          
        />
      </div>
    );
  }
}