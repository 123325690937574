import React from 'react';
// import axios from 'axios';
import $ from 'jquery';
// import components
import Links from '../../components/navigations/Links';
// import AvgReview from '../../components/startRating/avgReview';
// import asstes
// import reviewAvtar from '../../assets/images/marketplaceDetails/reviewAvtar.svg';
// import fourstar from '../../assets/images/rating/four.svg';
// import prevarrow from '../../assets/images/pagination/prev.png';
// import nextarrow from '../../assets/images/pagination/next.svg';
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';




export default class PendingReview extends React.Component{

	constructor(){
		super();
		this.state = {
			reviews : [],
			start: 0,
			end: 8,
			showPerPage: 8,
			isClick: false,
			showText: false,
		}
	}

	approveReview(e){
		var url = devconfig.vendorV3+"/product/process-review";
		var data = JSON.stringify({
			"review_id":  parseInt(e),
			"approve" :  true,
		})
		 APIPost(url, data).then(function(response){
		 	 if(response.status === "ok"){
        		this.props.reload();

			}
      else{
        this.setState({ dataLoading: false});
      }
      
    }.bind(this))
	}

	deleteReview(e){
		var url = devconfig.vendorV3+"/product/process-review";
		var data = JSON.stringify({
			"review_id":  parseInt(e),
			"approve" :  false,
		})
		 APIPost(url, data).then(function(response){
		 	 if(response.status === "ok"){
        		this.props.reload();

			}
      else{
        this.setState({ dataLoading: false});
      }
      
    }.bind(this))
	}

	render(){
	
		return(
			<React.Fragment>
				<div className="card border-0 p-3 mt-4 e-review-card" >	
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-2 col-2">
							<img className=" e-reviewer" src={this.props.data.image} alt="user image"/>
  					</div>
	  				<div className="col-lg-10 col-md-10 col-sm-10 col-10">
  						<h5 className="e-reviewer-name">{this.props.data.name} </h5>
  						<div className="d-flex justify-content-between">
  							<div>
  								<p className="e-review-date m-0">{this.props.data.date}</p>
  							</div>
  							<div className="e-review-s">
  								{/*<AvgReview rating={this.props.data.rating}/>*/}
  							</div>
   						 </div>
   					</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">
    					<p className=" e-review-content">{this.props.data.review}</p>
    					{
        				(this.props.data.review.length > 150)?
        					<a href="javascript:void();" className="e-read-more" onClick={()=>this.readMore()}>Read More</a>
        				: null
        			}
        					<button className="text-left e-review-edit" onClick={()=>this.approveReview(this.props.data.id)}>
        					Approve</button>
        					<button className="text-left e-review-mark" onClick={()=>this.deleteReview(this.props.data.id)}>
        					hide</button>
        			
							
						</div>
					</div>
				</div>
			</React.Fragment>
			
		);

		}

	}